/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useState } from "react"
import MealsList from "./MealsList"
import { withRouter } from "react-router-dom"
import FilterPanel from "./FilterPanel"
import MealsGroupedSummary from "./MealsGroupedSummary"
import MealsGroupedList from "./MealsGroupedList"
import { getMeals } from "../_axios/itemCrud"
import { getCustomersList } from "../../Customers/_axios/customerCrud"
import { formatDateForApi } from "../../Common/momentFunctions"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../../redux/snackbar/snackbarHandlers"
import { injectIntl } from "react-intl"
import DishLoader from "../../Common/DishLoader"
import ListsWrapper from "../../Reports/ListsWrapper"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { useStyles } from "../../Common/_styles/listWrapperStyles"
import moment from "moment"

function MealsPanel({ intl, isActiveTab, selectedSupplierID, isUSFormatDate, ...props }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [mealsData, setMealsData] = useState({
    meals: [],
    isLoading: true
  })
  const [searchParams, setSearchParams] = useState({})
  const [customerData, setCustomerData] = useState({
    customers: [],
    isLoading: true
  })

  function handleSearchParamsSave(changes) {
    const newParams = { ...searchParams, ...changes }
    setSearchParams(newParams)
  }

  function fetchMeals(cancelToken) {
    const { mealType, date, customer, groupBy } = searchParams

    const params = {
      tab: "meals",
      groupBy,
      mealType,
      date: formatDateForApi(date),
      customer: customer.CustomerID
    }

    if (isActiveTab) {
      props.history.push({
        pathname: `/supplier/${selectedSupplierID}`,
        search: "?" + new URLSearchParams(params).toString()
      })
    }

    setMealsData({ ...mealsData, isLoading: true })
      getMeals(selectedSupplierID, mealType, params.date, groupBy, customer.CustomerID, cancelToken.token)
          .then(({ data }) => {
              console.log(data);
        setMealsData({
          meals: data.Orders,
          reports: data.Orders,
          isLoading: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_SUPPLIER_MEALS"
          })
        )
      )
    }


    function getExportData() {
        const quantity = mealsData.meals.reduce((sum, i) => sum += i.GroupedItems.reduce((s, t) => s + t.Quantity, 0), 0)

        const formattedReport = []
        var mainHeaderNameId = "FILTER.GROUP_BY_DEPARTMENT"; 
        mealsData.meals.forEach(report => {
            report.GroupedItems.forEach(meal => {
                const formattedMeal = {}
                mainHeaderNameId = report.DepartmentName != null ? "FILTER.GROUP_BY_DEPARTMENT" : "FILTER.GROUP_BY_LOCATIONS";
                formattedMeal[
                    intl.formatMessage({
                        id: mainHeaderNameId
                    })
                ] = report.DepartmentName ?? report.LocationSupplierDisplayName
                formattedMeal[
                    intl.formatMessage({
                        id: "REPORT.ITEM_NAME"
                    })
                ] = meal.ItemName
                formattedMeal[
                    intl.formatMessage({
                        id: "REPORT.QUANTITY"
                    })
                ] = meal.Quantity
                formattedReport.push(formattedMeal)
            })
        })
        const formattedMeal = {}
        formattedMeal[
            intl.formatMessage({
                id: mainHeaderNameId
            })
        ] = intl.formatMessage({
            id: "REPORT.TOTAL"
        })
        formattedMeal[
            intl.formatMessage({
                id: "REPORT.QUANTITY"
            })
        ] = quantity
        formattedReport.push(formattedMeal)
        return formattedReport
    }

    function getExportDataSimple() {
        const quantity = mealsData.meals.reduce((sum, i) => sum += i.Quantity, 0)

        const formattedReport = []
        mealsData.meals.forEach(meal => {
            const formattedMeal = {}
            formattedMeal[
                intl.formatMessage({
                    id: "REPORT.ITEM_NAME"
                })
            ] = meal.ItemName
            formattedMeal[
                intl.formatMessage({
                    id: "REPORT.QUANTITY"
                })
            ] = meal.Quantity
            formattedReport.push(formattedMeal)
        })
        const formattedMeal = {}
        formattedMeal[
            intl.formatMessage({
                id: "REPORT.QUANTITY"
            })
        ] = quantity
        formattedReport.push(formattedMeal)
        return formattedReport
    }

  function fetchCustomers(cancelToken) {
    getCustomersList(cancelToken.token, true, "active")
      .then(({ data }) => {
        setCustomerData({ customers: data, isLoading: false })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
          })
        )
      )
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchCustomers(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (!customerData.isLoading) {
      const urlParams = new URLSearchParams(window.location.search)

      const areParamsPresent = urlParams.get("tab") === "meals"

      let initialParams = {}
      if (areParamsPresent) {
        initialParams = {
          mealType: urlParams.get("mealType") || 2,
          groupBy: urlParams.get("groupBy"),
          date: urlParams.get("date")
            ? new Date(urlParams.get("date"))
            : new Date(),
          customer:
            customerData.customers.find(
              customer => customer.CustomerID === urlParams.get("customer")
            ) || {}
        }
      } else {
        initialParams = {
          mealType: 2,
          groupBy: "",
          date: new Date(),
          customer: {}
        }
      }
      setSearchParams(initialParams)
    }
  }, [customerData])

  useEffect(() => {
    if (searchParams.customer) {
      const cancelToken = axios.CancelToken.source()
      fetchMeals(cancelToken)
      return () => cancelToken.cancel()
    }
  }, [searchParams])

  return (
    <>
      {searchParams.customer && (
        <FilterPanel
          onSearchParamsSave={handleSearchParamsSave}
          customerData={customerData}
          initialSearchParams={searchParams}
          isUSFormatDate={isUSFormatDate}
        />
      )}
      {
              (!mealsData.isLoading && mealsData?.meals?.find(x => x.GroupedItems == null)) &&
              <ListsWrapper
                  showSummary={false}
                  reportsData={mealsData}
                  contents={<MealsList meals={mealsData.meals} />}
                  exportData={getExportDataSimple()}
                  exportFileName={"MealsReports_" + moment(searchParams.date).format("DD-MM-YYYY")}
               />
      }
          {
              (!mealsData.isLoading && mealsData?.meals?.find(x => x.GroupedItems != null)) &&
              <ListsWrapper
                  showSummary={false}
                  reportsData={mealsData}
                  contents={mealsData.meals.map(order => (
                      <Paper className={classes.paper} key={order.SupplierId}>
                          <Typography className={classes.heading}>
                              {order.DepartmentName ?? order.LocationSupplierDisplayName}
                          </Typography>
                          <MealsGroupedList ordersData={order} />
                          <MealsGroupedSummary ordersData={order} />
                      </Paper>
                  ))}
                  exportData={getExportData()}
                  exportFileName={"MealsReports_" + moment(searchParams.date).format("DD-MM-YYYY")}
              />
      }
      {
        mealsData.isLoading && <DishLoader center/>
      }
    </>
  )
}
export default injectIntl(withRouter(MealsPanel))
