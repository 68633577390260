/* eslint-disable no-restricted-imports */

import React, { useEffect, useState } from "react"
import {
  Button,
  Checkbox,
  Chip,
  FilledInput,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField
} from "@material-ui/core"
import { FormattedMessage, injectIntl } from "react-intl"
import { useFormik } from "formik"
import * as Yup from "yup"
import AddIcon from "@material-ui/icons/Add"
import ItemOptionList from "./ItemOptionList"
import AddItemOptionFormDialog from "./AddItemOptionFormDialog"
import SetDateRangeDialog from "./SetDateRangeDialog"
import moment from "moment"
import { dayCollection } from "../../Common/dayCollection"
import DeleteIcon from "@material-ui/icons/Delete"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { formatDateForApi, formatDate } from "../../Common/momentFunctions"
import DishLoader from "../../Common/DishLoader"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../../redux/snackbar/snackbarHandlers"
import { useStyles } from "../_styles/complexFormStyles"
import MealItemPricesList from "./MealItemPricesList"
import { updateMealItemPrices } from "../_axios/itemCrud"

function Thumb({ imageFile, initialURL, setStoredImageURL }) {
  const [state, setState] = useState({
    loading: false
  })

  useEffect(() => {
    setState({ loading: true })
    if (imageFile) {
      let reader = new FileReader()

      reader.onloadend = () => {
        setState({ loading: false, thumb: reader.result })
        setStoredImageURL(reader.result)
      }

      reader.readAsDataURL(imageFile)
    } else {
      setState({ loading: false, thumb: initialURL })
    }
  }, [imageFile, initialURL])

  if (!imageFile && !initialURL) {
    return null
  }

  if (state.loading) {
    return <span className="spinner spinner-white"></span>
  }
  return (
    <img
      src={state.thumb}
      onError={e => {
        e.target.onerror = null
        e.target.src = toAbsoluteUrl(`/media/svg/image_filler_icon.svg`)
      }}
      alt={imageFile ? imageFile.name : "Item"}
      className="img-thumbnail"
      height={200}
      width={200}
    />
  )
}

function ItemForm({
  intl,
  isUserAdmin,
  setSelectedItem,
  selectedItem,
  mealItemPricesData,
  setMealItemPricesData,
  items,
  sendActive,
  optionGroups,
  allTags,
  allPriceCategories,
  onSubmit,
  onDelete,
  saveImage,
  updateItems,
  createNewItemVersion,
}) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [createItemOptionData, setCreateItemOptionData] = useState({
    show: false
  })
  const [showDateRange, setShowDateRange] = useState(false)
  const [storedImageURL, setStoredImageURL] = useState("")
  const [priceCategoryMap, setPriceCategoryMap] = useState({})

  useEffect(() => {
    if (allPriceCategories) {
      const map = {};
      allPriceCategories.forEach(priceCategoryItem => {
        map[priceCategoryItem.Id] = priceCategoryItem;
      })
      setPriceCategoryMap(map);
    }
  }, [allPriceCategories])
  const initialValues = {
    name: selectedItem.Name,
    catalogNumber: selectedItem.CatalogNumber,
    description: selectedItem.Description || "",
    note: selectedItem.Note || "",
    type: selectedItem.Type || 1,
    priceCategoryID: selectedItem.PriceCategoryID,
    mealTypeUsage: (selectedItem.MealTypeUsage || []).filter(t => !!t).map(t => +t),
    tags: selectedItem.Tags ? selectedItem.Tags.map(tag => tag.TagID) : [],
    imageTags: selectedItem.ImageTags
      ? selectedItem.ImageTags.map(tag => tag.TagID)
      : [],
    price: selectedItem.Price || 0,
    // stickerNumber: selectedItem.StickerNumber || 1,
    stickerNumber: selectedItem.StrikerNumber || 1, //Remove after fix
    maxQuantity: selectedItem.MaxQuantity || 1,
    version: selectedItem.Version,
    status: selectedItem.Status,
    itemOptions: selectedItem.OptionGroups
      ? selectedItem.OptionGroups.map(optionGroup => ({
          ...optionGroup,
          Label: optionGroup.Lable, //Remove after fix
          OptionGroupID: optionGroup.OptionGroup.id
        }))
      : [],
    availableFrom: selectedItem.AvilableFrom
      ? new Date(`${selectedItem.AvilableFrom}.000Z`)
      : null,
    availableTo: selectedItem.AvilableTo
      ? new Date(`${selectedItem.AvilableTo}.000Z`)
      : null,
    availabiltyDays: selectedItem.AvilabiltyDays
      ? [...selectedItem.AvilabiltyDays]
      : [],
    isCurrentDayOnly: selectedItem.IsCurrentDayOnly,
    active: selectedItem.Active !== false ? true : false
  }
  if (isUserAdmin) {
    initialValues.customerPrice = selectedItem.CustomerPrice || 0
    initialValues.clientPrice = selectedItem.ClientPrice || 0
  }
  initialValues.imageFile = null

  const nameValidationSchema = Yup.string()
    .test(
      "nameIsUnique",
      intl.formatMessage({
        id: "VALIDATION.NAME_USED"
      }),
      value => {
        const item = items.find(item => item.Name === value)
        return item === undefined || item.ItemID === selectedItem.ItemID
      }
    )
    .required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )

  const statusValidationSchema = Yup.number().required(
    intl.formatMessage({
      id: "VALIDATION.REQUIRED_FIELD"
    })
  )

  const priceValidationSchema = Yup.number()
    .min(0, intl.formatMessage({ id: "VALIDATION.MIN_VALUE" }))
    .required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )

  const stickerNumberValidationSchema = Yup.number()
    .min(1, intl.formatMessage({ id: "VALIDATION.MIN_VALUE" }))
    .required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )

  const imageFileValidationSchema = Yup.mixed()
    .test(
      "fileType",
      intl.formatMessage({
        id: "VALIDATION.UNSUPPORTED_FILE_FORMAT"
      }),
      value => {
        if (value) {
          return ["image/jpeg", "image/png", "image/svg+xml"].includes(
            value.type
          )
        }
        return true
      }
    )
    .test(
      "fileSize",
      intl.formatMessage({
        id: "VALIDATION.FILE_TOO_BIG"
      }),
      value => {
        if (value) {
          return value.size <= 11 * 1024 * 1024
        }
        return true
      }
    )

  let validationSchema

  if (isUserAdmin) {
    validationSchema = Yup.object().shape(
      {
        name: nameValidationSchema,
        status: statusValidationSchema,
        price: priceValidationSchema,
        customerPrice: priceValidationSchema,
        clientPrice: priceValidationSchema,
        stickerNumber: stickerNumberValidationSchema,
        maxQuantity: stickerNumberValidationSchema,
        imageFile: imageFileValidationSchema
      },
      [
        "name",
        "status",
        "price",
        "customerPrice",
        "clientPrice",
        "stickerNumber",
        "maxQuantity",
        "imageFile"
      ]
    )
  } else {
    validationSchema = Yup.object().shape(
      {
        name: nameValidationSchema,
        status: statusValidationSchema,
        price: priceValidationSchema,
        stickerNumber: stickerNumberValidationSchema,
        maxQuantity: stickerNumberValidationSchema,
        imageFile: imageFileValidationSchema
      },
      ["name", "status", "price", "stickerNumber", "maxQuantity", "imageFile"]
    )
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      const {
        imageFile,
        name,
        catalogNumber,
        description,
        note,
        type,
        mealTypeUsage,
        tags,
        imageTags,
        price,
        customerPrice,
        clientPrice,
        stickerNumber,
        priceCategoryID,
        maxQuantity,
        status,
        itemOptions,
        availabiltyDays,
        isCurrentDayOnly,
        active
      } = values

      let availableFrom = null
      let availableTo = null
      if (values.availableFrom) {
        availableFrom = formatDateForApi(values.availableFrom)
        availableTo = formatDateForApi(values.availableTo)
      }

      const newItem = {}

      newItem.Version = selectedItem.Version
      newItem.Name = name
      newItem.CatalogNumber = catalogNumber
      newItem.Note = note
      newItem.Description = description
      newItem.Type = type
      newItem.MealTypeUsage = (mealTypeUsage || []).map(t => t + '').filter(t => !!t)
      newItem.Tags = allTags.filter(tag => tags.includes(tag.TagID))
      newItem.ImageTags = allTags.filter(tag => imageTags.includes(tag.TagID))
      newItem.Price = price
      newItem.PriceCategoryID = priceCategoryID
      // newItem.StickerNumber = stickerNumber
      newItem.StrikerNumber = stickerNumber //Remove after fix
      newItem.MaxQuantity = maxQuantity
      newItem.Status = status
      // newItem.AvailableFrom = availableFrom
      // newItem.AvailableTo = availableTo
      // newItem.AvailabiltyDays = availabiltyDays
      newItem.AvilableFrom = availableFrom //Remove after fix
      newItem.AvilableTo = availableTo //Remove after fix
      newItem.AvilabiltyDays = availabiltyDays //Remove after fix
      newItem.IsCurrentDayOnly = isCurrentDayOnly
      newItem.OptionGroups = itemOptions.map(item => ({
        ...item,
        Lable: item.Label, //Remove after fix
        OptionGroup: optionGroups.find(
          optionGroup => optionGroup.id === item.OptionGroupID
        )
      }))
      newItem.Active = active

      if (isUserAdmin) {
        newItem.CustomerPrice = customerPrice
        newItem.ClientPrice = clientPrice
      }
      newItem.ItemID = selectedItem.ItemID

      function submitItem() {
        onSubmit(newItem)
          .then(({ data }) => {
            if (imageFile) {
              saveImage(newItem.ItemID || data.ItemID, newItem.Version, imageFile)
                .then(() => {
                  setSubmitting(false)
                  updateItems({
                    ...newItem,
                    Image: [{ URL: storedImageURL }],
                    ItemID: newItem.ItemID
                  })
                  resetForm()
                })
                .catch(error => {
                  setSubmitting(false)
                  handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                      id: "API.ERROR.FAILED_TO_SAVE_IMAGE"
                    })
                  )
                })
            } else {
              updateItems({
                ...newItem,
                ItemID: newItem.ItemID || data.ItemID
              })
              setSubmitting(false)
              resetForm()
            }
          })
          .catch(error => {
            handleApiError(
              dispatch,
              error,
              intl.formatMessage({
                id: "API.ERROR.FAILED_TO_UPDATE_ITEM"
              })
            )
            setSubmitting(false)
          })
      }

      if (isUserAdmin) {
        updateMealItemPrices(mealItemPricesData.prices, selectedItem.ItemID)
          .then(() => submitItem())
          .catch(error => {
            handleApiError(
              dispatch,
              error,
              intl.formatMessage({
                id: "API.ERROR.FAILED_TO_UPDATE_MEAL_PRICES"
              })
            )
            setSubmitting(false)
          })
      } else {
        submitItem()
      }
    }
  })

  function onItemOptionDelete(deletedItemOption) {
    formik.setFieldValue(
      "itemOptions",
      formik.values.itemOptions.filter(
        itemOption => itemOption.Label !== deletedItemOption.Label
      )
    )
  }

  function openAddItemOptionForm() {
    setCreateItemOptionData({ show: true })
  }

  function handleAddItemOption(itemOption) {
    if (createItemOptionData.index !== undefined) {
      const options = [...formik.values.itemOptions]
      options[createItemOptionData.index] = itemOption
      formik.setFieldValue("itemOptions", [...options])
    } else {
      formik.setFieldValue("itemOptions", [
        ...formik.values.itemOptions,
        itemOption
      ])
    }
    setCreateItemOptionData({ show: false })
  }

  function openEditItemOptionForm(optionForEditing) {
    const index = formik.values.itemOptions.findIndex(
      option => option.Label === optionForEditing.Label
    )
    setCreateItemOptionData({
      show: true,
      index
    })
  }

  function handleSetDateRangeDialog(availableFrom, availableTo) {
    formik.setFieldValue("availableFrom", availableFrom)
    formik.setFieldValue("availableTo", availableTo)
    setShowDateRange(false)
  }

  function handleChangeMealPrice(event, priceID) {
    const {value, name} = event.target

    if (+value < 0) { return }

    const mealItemPrices = [...mealItemPricesData.prices]

    const changedPrice = mealItemPrices.find(price => price.id === priceID)
    changedPrice[name] = +value

    setMealItemPricesData({...mealItemPricesData, prices: mealItemPrices})
  }

  function sendForReview() {
    formik.validateForm().then(errors => {
      if (Object.values(errors).length === 0) {
        formik.setFieldValue("status", 2)
        formik.submitForm()
      } else {
        formik.submitForm()
      }
    })
  }

  function checkForError(fieldName) {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true
    }
    return false
  }

  function renderErrors(fieldName) {
    return checkForError(fieldName) ? (
      <span className={classes.error}>{formik.errors[fieldName]}</span>
    ) : null
  }

  function createNewVersion() {
    createNewItemVersion()
        .then((response) => {
        var newItemIdByVersion = response.data.ItemID;
        const newVersion =
          1 +
          selectedItem.availableVersions.reduce(
            (max, version) => (version > max ? version : max),
            1
          )
        setSelectedItem({
          ...selectedItem,
          Status: 1,
          Version: newVersion,
          ItemIdByVersion: newItemIdByVersion
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_CREATE_NEW_ITEM_VERSION"
          })
        )
      )
  }

  useEffect(() => {
    if (
      selectedItem.availableVersions &&
      selectedItem.availableVersions.includes(formik.values.version)
    ) {
      setSelectedItem({
        ...selectedItem,
        Version: formik.values.version
      })
    }
  }, [formik.values.version])

  const isReadOnly = !isUserAdmin && formik.values.status !== 1

  function getTypeValue(value) {
    switch(+value) {
      case 1: {
          return intl.formatMessage({
            id: 'MEAL_TYPE.VALUE.BREAKFAST'
          });
        }
      case 2: {
          return intl.formatMessage({
          id: 'MEAL_TYPE.VALUE.LUNCH'
        });
      }
      case 3: {
        return intl.formatMessage({
          id: 'MEAL_TYPE.VALUE.DINNER'
        });
      }
      case 4: {
        return intl.formatMessage({
          id: 'MEAL_TYPE.VALUE.STORE'
        });
      }
    }
  }

  return (
    <div>
      <AddItemOptionFormDialog
        show={createItemOptionData.show}
        saveItemOption={handleAddItemOption}
        closeDialog={() => setCreateItemOptionData({ show: false })}
        optionGroups={optionGroups}
        itemOptionData={formik.values.itemOptions[createItemOptionData.index]}
        itemOptions={formik.values.itemOptions}
      />
      <SetDateRangeDialog
        intl={intl}
        show={showDateRange}
        saveRange={handleSetDateRangeDialog}
        closeDialog={() => setShowDateRange(false)}
        initialStartDate={formik.values.availableFrom}
        initialEndDate={formik.values.availableTo}
      />
      <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <div className="row pt-5">
              <div className="col-12 d-flex justify-content-center align-items-center">
                <FormControl
                  variant="filled"
                  className={classes.smallTextField}
                  fullWidth
                >
                  <Select
                    {...formik.getFieldProps("version")}
                    input={
                      <FilledInput
                        name="version"
                        inputProps={{
                          className: classes.input
                        }}
                        id="item-version-select"
                      />
                    }
                  >
                    {selectedItem.availableVersions ? (
                      selectedItem.availableVersions.map(version => (
                        <MenuItem key={version} value={version}>
                          {version}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={1}>1</MenuItem>
                    )}
                    {selectedItem.availableVersions &&
                      !selectedItem.availableVersions.includes(
                        formik.values.version
                      ) && (
                        <MenuItem value={formik.values.version}>
                          {formik.values.version}
                        </MenuItem>
                      )}
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  type="button"
                  size="large"
                  color="secondary"
                  onClick={createNewVersion}
                  className={classes.button}
                  disabled={
                    formik.isSubmitting ||
                    !selectedItem.availableVersions ||
                    items
                      .find(item => item.ItemID === selectedItem.ItemID)
                      .Versions.find(
                        version => version.Status === 3 || version.Status === 4
                      ) === undefined ||
                    items
                      .find(item => item.ItemID === selectedItem.ItemID)
                      .Versions.find(version => version.Status === 1) !==
                      undefined ||
                    !selectedItem.availableVersions.includes(
                      formik.values.version
                    )
                  }
                >
                  <FormattedMessage id="CREATE_FORM.NEW_VERSION_BUTTON" />
                </Button>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.active}
                      name="active"
                      disabled={isReadOnly}
                      color="secondary"
                      {...formik.getFieldProps("active")}
                    />
                  }
                  label={intl.formatMessage({
                    id: "CREATE_FORM.ACTIVE_FIELD.LABEL"
                  })}
                />
                <Button
                  variant="contained"
                  type="button"
                  size="large"
                  className={classes.button}
                  disabled={formik.isSubmitting}
                  startIcon={<DeleteIcon />}
                  onClick={() =>
                    onDelete(selectedItem.ItemID, formik.values.version)
                  }
                >
                  <FormattedMessage id="CREATE_FORM.DELETE_BUTTON" />
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <div className="row">
                  <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_FORM.NAME_FIELD.LABEL" />
                  </div>
                  <div className="col-8 pl-0">
                    <TextField
                      id="item-name"
                      className={classes.textField}
                      margin="normal"
                      variant="filled"
                      hiddenLabel
                      inputProps={{
                        className: classes.input,
                        readOnly: isReadOnly
                      }}
                      fullWidth
                      {...formik.getFieldProps("name")}
                      error={checkForError("name")}
                    />
                    {renderErrors("name")}
                  </div>
                </div>


                <div className="row">
                  <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_FORM.CATALOG_NUMBER_FIELD.LABEL" />
                  </div>
                  <div className="col-8 pl-0">
                    <TextField
                        id="item-catalogNumber"
                        className={classes.textField}
                        margin="normal"
                        variant="filled"
                        hiddenLabel
                        inputProps={{
                          className: classes.input,
                          readOnly: isReadOnly
                        }}
                        fullWidth
                        {...formik.getFieldProps("catalogNumber")}
                        error={checkForError("catalogNumber")}
                    />
                    {renderErrors("catalogNumber")}
                  </div>
                </div>



                <div className="row">
                  <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_FORM.DESCRIPTION_FIELD.LABEL" />
                  </div>
                  <div className="col-8 pl-0">
                    <TextField
                      id="description"
                      className={classes.textField}
                      InputProps={{
                        className: classes.input,
                        readOnly: isReadOnly
                      }}
                      multiline
                      rows="4"
                      margin="normal"
                      variant="filled"
                      fullWidth
                      {...formik.getFieldProps("description")}
                      error={checkForError("description")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_FORM.NOTE_FIELD.LABEL" />
                  </div>
                  <div className="col-8 pl-0">
                    <TextField
                      id="item-note"
                      className={classes.textField}
                      InputProps={{
                        className: classes.input,
                        readOnly: isReadOnly
                      }}
                      multiline
                      rows="4"
                      margin="normal"
                      variant="filled"
                      fullWidth
                      {...formik.getFieldProps("note")}
                      error={checkForError("note")}
                    />
                  </div>
                </div>
                {/*<div className="row">
                  <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_FORM.TYPE_FIELD.LABEL" />
                  </div>
                  <div className="col-8 pl-0">
                    <FormControl
                      variant="filled"
                      className={classes.textField}
                      fullWidth
                    >
                      <Select
                        {...formik.getFieldProps("type")}
                        input={
                          <FilledInput
                            name="type"
                            inputProps={{
                              className: classes.input,
                              readOnly: isReadOnly
                            }}
                            id="type-select"
                          />
                        }
                      >
                        <MenuItem value={1}>
                          <FormattedMessage id="MEAL_TYPE.VALUE.BREAKFAST" />
                        </MenuItem>
                        <MenuItem value={2}>
                          <FormattedMessage id="MEAL_TYPE.VALUE.LUNCH" />
                        </MenuItem>
                        <MenuItem value={3}>
                          <FormattedMessage id="MEAL_TYPE.VALUE.DINNER" />
                        </MenuItem>
                        <MenuItem value={4}>
                          <FormattedMessage id="MEAL_TYPE.VALUE.STORE" />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>*/}

                <div className="row">
                  <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_FORM.TYPE_FIELD.LABEL" />
                  </div>
                  <div className="col-8 pl-0">
                    <FormControl
                        variant="filled"
                        className={classes.textField}
                        fullWidth
                    >
                      <Select
                          {...formik.getFieldProps("mealTypeUsage")}
                          multiple
                          input={
                            <FilledInput
                                name="type"
                                inputProps={{
                                  className: classes.input,
                                  readOnly: isReadOnly
                                }}
                                id="type-select"
                            />
                          }
                          renderValue={selected => (
                              <div className={classes.chips}>
                                {selected.map(value => (
                                    <Chip
                                        key={value}
                                        className={classes.chip}
                                        label={getTypeValue(value)}
                                    />
                                ))}
                              </div>
                          )}
                      >
                        <MenuItem value={1}>
                          <FormattedMessage id="MEAL_TYPE.VALUE.BREAKFAST" />
                        </MenuItem>
                        <MenuItem value={2}>
                          <FormattedMessage id="MEAL_TYPE.VALUE.LUNCH" />
                        </MenuItem>
                        <MenuItem value={3}>
                          <FormattedMessage id="MEAL_TYPE.VALUE.DINNER" />
                        </MenuItem>
                        <MenuItem value={4}>
                          <FormattedMessage id="MEAL_TYPE.VALUE.STORE" />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>


                <div className="row">
                  <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_FORM.TAGS_FIELD.LABEL" />
                  </div>
                  <div className="col-8 pl-0">
                    <FormControl
                      variant="filled"
                      className={classes.textField}
                      fullWidth
                      error={checkForError("tags")}
                    >
                      <Select
                        {...formik.getFieldProps("tags")}
                        multiple
                        input={
                          <FilledInput
                            name="tags"
                            inputProps={{
                              className: classes.input,
                              readOnly: isReadOnly
                            }}
                            id="tags-select"
                          />
                        }
                        renderValue={selected => (
                          <div className={classes.chips}>
                            {selected.map(value => (
                              <Chip
                                key={value}
                                className={classes.chip}
                                label={
                                  allTags.find(tag => tag.TagID === value).Name
                                }
                              />
                            ))}
                          </div>
                        )}
                      >
                        {allTags.map(tag => (
                          <MenuItem key={tag.Name} value={tag.TagID}>
                            {tag.Name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {renderErrors("tags")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_FORM.IMAGE_TAGS_FIELD.LABEL" />
                  </div>
                  <div className="col-8 pl-0">
                    <FormControl
                      variant="filled"
                      className={classes.textField}
                      fullWidth
                      error={checkForError("imageTags")}
                    >
                      <Select
                        {...formik.getFieldProps("imageTags")}
                        multiple
                        input={
                          <FilledInput
                            name="imageTags"
                            inputProps={{
                              className: classes.input,
                              readOnly: isReadOnly
                            }}
                            id="image-tags-select"
                          />
                        }
                        renderValue={selected => (
                          <div className={classes.chips}>
                            {selected.map(value => (
                              <Chip
                                key={value}
                                className={classes.chip}
                                label={
                                  allTags.find(tag => tag.TagID === value).Name
                                }
                              />
                            ))}
                          </div>
                        )}
                      >
                        {allTags.map(tag => (
                          <MenuItem key={tag.Name} value={tag.TagID}>
                            {tag.Name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {renderErrors("imageTags")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_FORM.PRICE_FIELD.LABEL" />
                  </div>
                  <div className="col-8 pl-0">
                    <TextField
                      id="price"
                      name="price"
                      className={classes.textField}
                      inputProps={{
                        min: 0,
                        className: classes.input,
                        readOnly: isReadOnly
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">₪</InputAdornment>
                        )
                      }}
                      margin="normal"
                      variant="filled"
                      type="number"
                      fullWidth
                      {...formik.getFieldProps("price")}
                      error={checkForError("price")}
                    />
                    {renderErrors("price")}
                  </div>
                </div>
                {isUserAdmin && (
                  <>
                    <div className="row">
                      <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                        <FormattedMessage id="OPTION.LABELS.CUSTOMER_PRICE" />
                      </div>
                      <div className="col-8 pl-0">
                        <TextField
                          id="customer-price"
                          name="customerPrice"
                          className={classes.textField}
                          inputProps={{
                            min: 0,
                            className: classes.input,
                            readOnly: isReadOnly
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">₪</InputAdornment>
                            )
                          }}
                          margin="normal"
                          variant="filled"
                          type="number"
                          fullWidth
                          {...formik.getFieldProps("customerPrice")}
                          error={checkForError("customerPrice")}
                        />
                        {renderErrors("customerPrice")}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                        <FormattedMessage id="OPTION.LABELS.CLIENT_PRICE" />
                      </div>
                      <div className="col-8 pl-0">
                        <TextField
                          id="client-price"
                          name="clientPrice"
                          className={classes.textField}
                          inputProps={{
                            min: 0,
                            className: classes.input,
                            readOnly: isReadOnly
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">₪</InputAdornment>
                            )
                          }}
                          margin="normal"
                          variant="filled"
                          type="number"
                          fullWidth
                          {...formik.getFieldProps("clientPrice")}
                          error={checkForError("clientPrice")}
                        />
                        {renderErrors("clientPrice")}
                      </div>
                    </div>
                  </>
                )}
                <div className="row">
                  <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_FORM.STICKER_NUMBER_FIELD.LABEL" />
                  </div>
                  <div className="col-8 pl-0">
                    <TextField
                        id="sticker-number"
                        className={classes.textField}
                        margin="normal"
                        variant="filled"
                        type="number"
                        hiddenLabel
                        inputProps={{
                          min: 0,
                          className: classes.input,
                          readOnly: isReadOnly
                        }}
                        fullWidth
                        {...formik.getFieldProps("stickerNumber")}
                        error={checkForError("stickerNumber")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_FORM.PRICE_CATEGORY_FIELD.LABEL" />
                  </div>
                  <div className="col-8 pl-0">
                    <FormControl
                        variant="filled"
                        className={classes.textField}
                        fullWidth
                    >
                      <Select
                          {...formik.getFieldProps("priceCategoryID")}
                          input={
                            <FilledInput
                                name="priceCategoryID"
                                inputProps={{
                                  className: classes.input
                                }}
                                id="item-price-categoey-id-select"
                            />
                          }
                      >
                        <MenuItem value={null}>
                          <FormattedMessage id="CREATE_FORM.PRICE_CATEGORY_FIELD.LABEL" />
                        </MenuItem>
                        {
                          allPriceCategories && allPriceCategories.map(p => {
                              return <MenuItem value={p.Id} key={p.Id}>
                                {priceCategoryMap[p.Id]?.Name}
                              </MenuItem>
                            })
                        }
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_FORM.MAX_QUANTITY_NUMBER_FIELD.LABEL" />
                  </div>
                  <div className="col-8 pl-0">
                    <TextField
                      id="quantity-number"
                      className={classes.textField}
                      margin="normal"
                      variant="filled"
                      type="number"
                      hiddenLabel
                      inputProps={{
                        min: 0,
                        className: classes.input,
                        readOnly: isReadOnly
                      }}
                      fullWidth
                      {...formik.getFieldProps("maxQuantity")}
                      error={checkForError("maxQuantity")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-7 d-flex flex-column justify-content-center">
                <div className="position-relative d-flex flex-row mt-2 pb-2">
                  <div className="col-8 text-center p-0">
                    <Thumb
                      imageFile={formik.values.imageFile}
                      initialURL={
                        selectedItem.Image.length !== 0
                          ? selectedItem.Image[0].URL
                          : undefined
                      }
                      setStoredImageURL={setStoredImageURL}
                    />
                  </div>
                  <div className="col-2 text-right align-self-end p-0">
                    <Button
                      variant="contained"
                      component="label"
                      className={classes.chooseImageButton}
                    >
                      ...
                      <input
                        id="image-file"
                        name="imageFile"
                        type="file"
                        accept="image/*"
                        disabled={isReadOnly}
                        onChange={event => {
                          formik.setFieldValue(
                            "imageFile",
                            event.currentTarget.files[0]
                          )
                        }}
                        hidden
                      />
                    </Button>
                  </div>
                  {renderErrors("imageFile")}
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center mt-2 mb-2">
                  <TextField
                    id="available-from"
                    name="availableFrom"
                    placeholder={intl.formatMessage({
                      id: "AVAILABLE_FROM.PLACEHOLDER"
                    })}
                    className={classes.textField}
                    inputProps={{
                      className: classes.input,
                      readOnly: true
                    }}
                    margin="normal"
                    variant="filled"
                    fullWidth
                    onClick={() => setShowDateRange(true)}
                    {...formik.getFieldProps("availableFrom")}
                    value={
                      formik.values.availableFrom
                        ? moment(formik.values.availableFrom).format(
                            "DD/MM/YYYY"
                          )
                        : ""
                    }
                    error={checkForError("availableFrom")}
                  />
                  <div>-</div>
                  <TextField
                    id="available-to"
                    name="availableTo"
                    placeholder={intl.formatMessage({
                      id: "AVAILABLE_TO.PLACEHOLDER"
                    })}
                    className={classes.textField}
                    inputProps={{
                      className: classes.input,
                      readOnly: true
                    }}
                    margin="normal"
                    variant="filled"
                    fullWidth
                    onClick={() => setShowDateRange(true)}
                    {...formik.getFieldProps("availableTo")}
                    value={
                      formik.values.availableTo
                        ? formatDate(formik.values.availableTo)
                        : ""
                    }
                    error={checkForError("availableTo")}
                  />
                </div>
                <div className="d-flex flex-row align-items-center mt-2 mb-2">
                  {dayCollection.map(day => (
                    <FormControlLabel
                      key={day.value}
                      style={{ margin: 0 }}
                      control={
                        <Checkbox
                          checked={formik.values.availabiltyDays.includes(
                            day.value
                          )}
                          style={{ padding: 0 }}
                          onChange={e => {
                            if (e.target.checked) {
                              formik.setFieldValue("availabiltyDays", [
                                ...formik.values.availabiltyDays,
                                day.value
                              ])
                            } else {
                              formik.setFieldValue(
                                "availabiltyDays",
                                formik.values.availabiltyDays.filter(
                                  value => value !== day.value
                                )
                              )
                            }
                          }}
                          disabled={formik.values.isCurrentDayOnly}
                          color="secondary"
                        />
                      }
                      label={day.label}
                      labelPlacement="end"
                    />
                  ))}
                </div>
                <div>
                  <Button
                    variant="contained"
                    type="button"
                    size="large"
                    color="secondary"
                    className={classes.button}
                    disabled={isReadOnly}
                    onClick={openAddItemOptionForm}
                  >
                    <AddIcon />
                    <FormattedMessage id="CREATE_FORM.OPTION_GROUP_TABLE.TITLE" />
                  </Button>
                  <FormControlLabel
                    style={{ margin: 0, marginRight: "10px" }}
                    control={
                      <Checkbox
                        checked={formik.values.isCurrentDayOnly}
                        style={{ padding: 0 }}
                        onChange={e => {
                          const isChecked = e.target.checked;
                          formik.setFieldValue("isCurrentDayOnly", isChecked);
                          if (isChecked) {
                            formik.setFieldValue("availabiltyDays", []);
                          }
                        }}
                        color="secondary"
                      />
                    }
                    label={<FormattedMessage id="CREATE_FORM.IS_CURRENT_DAY_FIELD.LABEL" />}
                    labelPlacement="end"
                  />
                </div>
                <div className="position-relative">
                  <ItemOptionList
                    itemOptions={formik.values.itemOptions}
                    optionGroups={optionGroups}
                    onDelete={onItemOptionDelete}
                    onEdit={openEditItemOptionForm}
                    isReadOnly={isReadOnly}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 pr-0" />
              <div className="col-6 pl-0 d-flex align-items-center">
                {isUserAdmin && (
                  <FormControl
                    variant="filled"
                    className={classes.statusTextField}
                    fullWidth
                  >
                    <Select
                      {...formik.getFieldProps("status")}
                      input={
                        <FilledInput
                          name="status"
                          inputProps={{
                            className: classes.input
                          }}
                          id="item-status-select"
                        />
                      }
                    >
                      <MenuItem value={1}>
                        <FormattedMessage id="CREATE_FORM.STATUS_FIELD.VALUE.DRAFT" />
                      </MenuItem>
                      <MenuItem value={2}>
                        <FormattedMessage id="CREATE_FORM.STATUS_FIELD.VALUE.WAITING" />
                      </MenuItem>
                      <MenuItem value={3}>
                        <FormattedMessage id="CREATE_FORM.STATUS_FIELD.VALUE.APPROVED" />
                      </MenuItem>
                      <MenuItem value={4}>
                        <FormattedMessage id="CREATE_FORM.STATUS_FIELD.VALUE.REJECTED" />
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
                <Button
                  variant="contained"
                  type="submit"
                  size="large"
                  color="secondary"
                  className={classes.button}
                  disabled={formik.isSubmitting || isReadOnly}
                >
                  <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON" />
                  {formik.isSubmitting && (
                    <span className="ml-1 spinner spinner-white"></span>
                  )}
                </Button>
                {
                  isReadOnly && (
                        <Button
                            variant="contained"
                            type="button"
                            size="large"
                            color="secondary"
                            onClick={() => {
                              sendActive({
                                row: {
                                  ItemID: selectedItem.ItemID,
                                  Version: selectedItem.Versions,
                                },
                                active: !formik.getFieldProps("active")?.value
                              })
                              formik.setFieldValue("active", !formik.getFieldProps("active")?.value)
                            }}
                            className={classes.button}
                            disabled={formik.isSubmitting}
                        >
                          {
                            formik.getFieldProps("active")?.value ?
                                <FormattedMessage id="CREATE_FORM.MARK_LiKE_IN_ACTIVE_BUTTON" />
                                :
                                <FormattedMessage id="CREATE_FORM.MARK_LiKE_ACTIVE_BUTTON" />
                          }

                          {formik.isSubmitting && (
                              <span className="ml-1 spinner spinner-white"></span>
                          )}
                        </Button>
                    )
                }
                {!isUserAdmin && (
                  <Button
                    variant="contained"
                    type="button"
                    size="large"
                    color="secondary"
                    onClick={sendForReview}
                    className={classes.button}
                    disabled={formik.isSubmitting || isReadOnly}
                  >
                    <FormattedMessage id="CREATE_FORM.SEND_TO_REVIEW_BUTTON" />
                    {formik.isSubmitting && (
                      <span className="ml-1 spinner spinner-white"></span>
                    )}
                  </Button>
                )}
              </div>
            </div>
            {isUserAdmin && (
              <div className="row">
                <div className="col-12 pl-6 pr-6">
                  <MealItemPricesList
                    pricesData={mealItemPricesData}
                    onChangeMealPrice={handleChangeMealPrice}
                  />
                </div>
              </div>
            )}
            <div className="pb-5"/>
          </Paper>
        </div>
      </form>
    </div>
  )
}

export default injectIntl(ItemForm)
