/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import SearchIcon from "@material-ui/icons/Search"
import ClearIcon from "@material-ui/icons/Clear"
import Button from "@material-ui/core/Button"
import { FormattedMessage, injectIntl } from "react-intl"
import { Input, Paper } from "@material-ui/core"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { he, enUS } from "date-fns/esm/locale"
import Downshift from "downshift"
import {
  renderInput,
  getSuggestions,
  renderSuggestion
} from "../../Common/downshiftFunctions"
import { useStyles } from "../../Common/_styles/orderFilterStyles"

function FilterPanel({
  onSearchParamsSave,
  initialSearchParams,
  customerData,
  isUSFormatDate = false,
  intl
}) {
  const locale = intl.locale === "en" ? enUS : he;
  const classes = useStyles()

  const defaultSearchParams = {
    date: new Date(),
    mealType: 2,
    groupBy: "",
    customer: {}
  }
  const [searchParams, setSearchParams] = useState(initialSearchParams)

  const handleSearchParamChange = name => data => {
    let value
    if (data.target) {
      value = data.target.value
    } else {
      value = data
    }
    setSearchParams(oldsearchParams => ({
      ...oldsearchParams,
      [name]: value
    }))
  }

  function handleSearchParamsClear() {
    setSearchParams({ ...searchParams, ...defaultSearchParams })
    onSearchParamsSave(defaultSearchParams)
  }

  function handleSearchParamsSubmit(event) {
    event.preventDefault()
    onSearchParamsSave(searchParams)
  }

  return (
    <form
      className={classes.root}
      onSubmit={handleSearchParamsSubmit}
      autoComplete="off"
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils} 
            locale={locale}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="mealType">
            <FormattedMessage id="ORDER_ITEMS.TYPE_FIELD.LABEL" />
          </InputLabel>
          <Select
            value={searchParams.mealType}
            onChange={handleSearchParamChange("mealType")}
            inputProps={{
              name: "mealType",
              id: "mealType"
            }}
          >
            <MenuItem value={1}>
              <FormattedMessage id="MEAL_TYPE.VALUE.BREAKFAST" />
            </MenuItem>
            <MenuItem value={2}>
              <FormattedMessage id="MEAL_TYPE.VALUE.LUNCH" />
            </MenuItem>
            <MenuItem value={3}>
              <FormattedMessage id="MEAL_TYPE.VALUE.DINNER" />
            </MenuItem>
            <MenuItem value={4}>
              <FormattedMessage id="MEAL_TYPE.VALUE.STORE" />
            </MenuItem>
          </Select>
        </FormControl>
        <DatePicker
          autoOk
          format={isUSFormatDate ? "MM/dd/yyyy" : "dd/MM/yyyy"}
          value={searchParams.date}
          onChange={handleSearchParamChange("date")}
          TextFieldComponent={({ helperText, InputProps, ...props }) => (
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="date">
                <FormattedMessage id="DATE_FIELD.LABEL" />
              </InputLabel>
              <Input
                inputProps={{
                  className: classes.input
                }}
                fullWidth
                {...props}
              />
            </FormControl>
          )}
        />
        <Downshift
          id="downshift-for-customer"
          selectedItem={
            searchParams.customer.Name ? searchParams.customer : null
          }
          onChange={selected =>
            handleSearchParamChange("customer")(selected || {})
          }
          itemToString={item => (item ? item.Name : "")}
        >
          {({
            clearSelection,
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            openMenu,
            selectedItem
          }) => {
            const { onChange, onBlur, onFocus, ...inputProps } = getInputProps({
              onChange: event => {
                if (event.target.value === "") {
                  clearSelection()
                }
              },
              onFocus: openMenu,
              placeholder: intl.formatMessage({
                id: "FILTER.CUSTOMER_FIELD.PLACEHOLDER.ALL"
              })
            })

            return (
              <div className={classes.container}>
                {renderInput({
                  fullWidth: true,
                  classes,
                  label: intl.formatMessage({
                    id: "FILTER.CUSTOMER_FIELD.LABEL"
                  }),
                  nested: true,
                  InputLabelProps: getLabelProps({
                    shrink: true
                  }),
                  InputProps: { onBlur, onChange, onFocus },
                  inputProps
                })}

                <div {...getMenuProps()}>
                  {isOpen ? (
                    <Paper className={classes.paper} square>
                      {getSuggestions(inputValue, customerData.customers, {
                        showEmpty: true
                      }).map((suggestion, index) =>
                        renderSuggestion({
                          suggestion,
                          index,
                          itemProps: getItemProps({
                            item: suggestion
                          }),
                          highlightedIndex,
                          selectedItem
                        })
                      )}
                    </Paper>
                  ) : null}
                </div>
              </div>
            )
          }}
        </Downshift>
      </MuiPickersUtilsProvider>
          <FormControl className={classes.formControl}>
              <InputLabel htmlFor="groupBy">
                  <FormattedMessage id="FILTER.GROUP_BY" />
              </InputLabel>
              <Select
                  value={searchParams.groupBy}
                  onChange={handleSearchParamChange("groupBy")}
                  inputProps={{
                      name: "groupBy",
                      id: "groupBy"
                  }}
              >
                  <MenuItem value="">
                      <FormattedMessage id="FILTER.GROUP_BY" />
                  </MenuItem>
                  <MenuItem value="Locations">
                      <FormattedMessage id="FILTER.GROUP_BY_LOCATIONS" />
                  </MenuItem>
                  <MenuItem value="Departments">
                      <FormattedMessage id="FILTER.GROUP_BY_DEPARTMENT" />
                  </MenuItem>
              </Select>
          </FormControl>
      <Button
        id="search_params_submit"
        type="submit"
        variant="contained"
        color="secondary"
        size="large"
        dir="ltr"
        className={`font-weight-bold my-3 ml-10`}
      >
        <span>
          <FormattedMessage id="FILTER.SEARCH_BUTTON" /> <SearchIcon />
        </span>
      </Button>
      <Button
        id="search_params_clear"
        type="button"
        variant="outlined"
        color="secondary"
        size="large"
        dir="ltr"
        className={`font-weight-bold my-3 ml-20`}
        onClick={handleSearchParamsClear}
      >
        <ClearIcon />
      </Button>
    </form>
  )
}

export default injectIntl(FilterPanel)
