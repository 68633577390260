/* eslint-disable no-restricted-imports */

import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { useStyles } from "../../Common/_styles/elementListStyles"

const headRows = [
  {
    id: "Icon",
    disablePadding: true,
    notSortable: true,
    label: ""
  },
  {
    id: "ItemName",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.ITEM_NAME" />
  },
  {
    id: "Quantity",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.QUANTITY" />
  }
]


function MealsGroupedList({ ordersData }) {
  const classes = useStyles()
  const rows = ordersData.GroupedItems
  const [sortBy, setSortBy] = useState("ItemName")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

    if (ordersData && ordersData.GroupedItems) {

        const quantity = rows.reduce((sum, item) => {
            return sum + item.Quantity
        }, 0);

        return (
            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}>
                    <Table aria-labelledby="tableTitle" size="small">
                        <TableBody>
                            <React.Fragment>
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    className={classes.collapsibleRow}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        align="left"
                                        padding="none"
                                        className={classes.collapseIcon}
                                    >
                                    </TableCell>
                                    <TableCell className={classes.itemName}>{' '}</TableCell>
                                    <TableCell className={classes.itemQuantity}>
                                        <b>
                                            {quantity}
                                        </b>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        )
    } else {
        return (<div></div>)
    }
}

export default MealsGroupedList
