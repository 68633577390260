/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import FilterPanel from "../modules/Reports/CreditCardReports/FilterPanel"
import { getCustomersList } from "../modules/Customers/_axios/customerCrud"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import moment from "moment"
import ReportsList from "../modules/Reports/CreditCardReports/ReportsList"
import { getCreditCardReports } from "../modules/Reports/_axios/reportsCrud"
import ExportButton from "../modules/Reports/ExportButton"
import { formatDateForApi } from "../modules/Common/momentFunctions"
import { useSelector, useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"

function CreditCustomerReportsPage({ intl, ...props }) {
    const dispatch = useDispatch()
    const type = 2//1 - creditCard 2 - customer credit
    const [reportsData, setReportsData] = useState({
        reports: [],
        isLoading: false,
        notRequested: true
    })
    const [exportFileName, setExportFileName] = useState("")
    const [searchParams, setSearchParams] = useState({})
    const userCustomerInfo = useSelector(state => state.auth.user.Customer)
    const userRole = useSelector(state => state.auth.user.Role)
    const [customerData, setCustomerData] = useState({
        customers: [],
        isLoading: true
    })

    function handleSearchParamsSave(changes) {
        const newParams = { ...searchParams, ...changes }
        setSearchParams(newParams)
    }

    function processReports(data) {
        return data
            .sort((a, b) => new Date(a.Date) - new Date(b.Date))
            .map(el => {
                if (
                    el.Items.length &&
                    !el.Items.find(item => item.SupplierName !== el.Items[0].SupplierName)
                ) {
                    el.ItemName = el.Items.map(item => item.ItemName.trim())
                    el.ItemQuantity = (Object.keys(el.ItemStatistics).map(item => el.ItemStatistics[item].length)).reduce((s, it) => s + it, 0)
                    el.ItemRefunded = el.Items.filter(item => item.RefundType).length
                    el.ItemCancelled = el.Items.filter(item => item.Status === 2).length
                    el.ItemTotal = el.ItemQuantity - el.ItemCancelled - el.ItemRefunded
                    el.SupplierName = el.Items[0].SupplierName;
                } else {
                    el.ItemName = []
                    el.SupplierName = ""
                }
                return el
            })
    }

    function fetchReports(cancelToken) {
        const {
            customer,
            location,
            from,
            to,
            firstName,
            lastName,
            filterByOrderToDate
        } = searchParams

        const params = {
            from: formatDateForApi(from),
            to: formatDateForApi(to)
        }
        if (customer.CustomerID) {
            params.customer = customer.CustomerID
            if (location) {
                params.location = location
            }
        }
        if (firstName) {
            params.firstName = firstName
        }
        if (lastName) {
            params.lastName = lastName
        }

        props.history.push({
            pathname: "/reports-customer-credit",
            search: "?" + new URLSearchParams(params).toString()
        })

        setReportsData({ ...reportsData, isLoading: true })
        getCreditCardReports(
            customer.CustomerID,
            location || null,
            params.from,
            params.to,
            params.firstName,
            params.lastName,
            type,
            cancelToken.token,
            filterByOrderToDate
        )
            .then(({ data }) => {
                setReportsData({
                    reports: processReports(data.Data),
                    isLoading: false,
                    notRequested: false
                })
            })
            .catch(error => handleApiError(dispatch, error))
    }

    function fetchCustomers(cancelToken) {
        getCustomersList(cancelToken.token, true, "active")
            .then(({ data }) => {
                setCustomerData({ customers: data, isLoading: false })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
                    })
                )
            )
    }

    useEffect(() => {
        const cancelToken = axios.CancelToken.source()
        if (userRole === "Customer Admin") {

            setCustomerData({ customers: [userCustomerInfo], isLoading: false })
        } else {
            fetchCustomers(cancelToken)
        }
        return () => cancelToken.cancel()
    }, [])

    useEffect(() => {
        if (!customerData.isLoading) {
            const urlParams = new URLSearchParams(window.location.search)

            const from = urlParams.get("from")
                ? new Date(urlParams.get("from"))
                : new Date()
            const to = urlParams.get("to")
                ? new Date(urlParams.get("to"))
                : new Date()
            const firstName = urlParams.get("firstName") || ""
            const lastName = urlParams.get("lastName") || ""
            const location = urlParams.get("location") || ""
            const customer = urlParams.get("customer")
                ? customerData.customers.find(
                    customer => customer.CustomerID === urlParams.get("customer")
                )
                : {}
            setSearchParams({
                from,
                to,
                location,
                customer,
                firstName,
                lastName
            })
        }
    }, [customerData])

    useEffect(() => {
        if (searchParams.from && searchParams.to) {
            const cancelToken = axios.CancelToken.source()
            fetchReports(cancelToken)

            const locationName = searchParams.location
                ? intl.formatMessage({
                    id: "EXPORT_FILE_LOCATION"
                }) +
                "_" +
                searchParams.customer.Locations.find(
                    el => el.LocationID === searchParams.location
                ).Name +
                "_"
                : ""

            const typeText =
                intl
                    .formatMessage({
                        id: "FILTER.TYPE_FIELD.LABEL"
                    })
                    .replace(" ", "_") + "_"

            const typeValue = intl
                .formatMessage({
                    id: "FILTER.TYPE_FIELD.VALUE.CUSTOMER_CREDIT"
                })
                .replace(" ", "_") + "_"

            const customerName = searchParams.customer.CustomerID
                ? intl.formatMessage({
                    id: "EXPORT_FILE_CUSTOMER"
                }) +
                "_" +
                searchParams.customer.Name +
                "_"
                : ""

            const firstName = searchParams.firstName
                ? intl
                    .formatMessage({
                        id: "FILTER.FIRST_NAME_FIELD.LABEL"
                    })
                    .replace(" ", "_") +
                "_" +
                searchParams.firstName +
                "_"
                : ""

            const lastName = searchParams.lastName
                ? intl
                    .formatMessage({
                        id: "FILTER.LAST_NAME_FIELD.LABEL"
                    })
                    .replace(" ", "_") +
                "_" +
                searchParams.lastName +
                "_"
                : ""

            setExportFileName(
                intl.formatMessage({
                    id: "EXPORT_FILE_NAME"
                }) +
                "_" +
                customerName +
                locationName +
                typeText +
                typeValue +
                firstName +
                lastName +
                moment(searchParams.from).format("DD-MM-YYYY") +
                "_-_" +
                moment(searchParams.to).format("DD-MM-YYYY")
            )
            return () => cancelToken.cancel()
        }
    }, [searchParams])

    function getExportData() {
        const amount = reportsData.reports.reduce((sum, item) => sum += item.Amount, 0)
        const quantity = reportsData.reports.reduce((sum, item) => sum += item.Items.reduce((s, i) => s + i.quantity, 0), 0)
        const quantityRefunded = reportsData.reports.reduce((sum, item) => sum += item.Items.filter(t => !!t.RefundType).reduce((s, i) => s + i.quantity, 0), 0)
        const quantityCancelled = reportsData.reports.reduce((sum, item) => sum += item.Items.filter(t => t.Status === 2).reduce((s, i) => s + i.quantity, 0), 0)
        const items = [];
        reportsData.reports.forEach(report => {
            Object.keys(report.ItemStatistics || {}).forEach((item, index) => {
                const formattedReport = {}
                formattedReport[
                    intl.formatMessage({
                        id: "REPORT.CUSTOMER"
                    })
                ] = report.CustomerName
                formattedReport[
                    intl.formatMessage({
                        id: "REPORT.USER_NAME"
                    })
                ] = report.UserName
                formattedReport[
                    intl.formatMessage({
                        id: "REPORT.AMOUNT"
                    })
                ] = index == 0 ? report.Amount : ''
                formattedReport[
                    intl.formatMessage({
                        id: "REPORT.APPROVAL_NUMBER"
                    })
                ] = report.ApprovalNumber
                formattedReport[
                    intl.formatMessage({
                        id: "REPORT.LAST_CREDIT_CARD_DIGITS"
                    })
                ] = report.LastCCDigits
                formattedReport[
                    intl.formatMessage({
                        id: "REPORT.SUPPLIER_NAME"
                    })
                ] = report.SupplierName
                formattedReport[
                    intl.formatMessage({
                        id: "REPORT.ITEM_NAME"
                    })
                ] = item
                formattedReport[
                    intl.formatMessage({
                        id: "REPORT.ITEM_QUANTITY"
                    })
                ] = (report.ItemStatistics || {})[item].reduce((s, i) => s + i.quantity, 0)
                formattedReport[
                    intl.formatMessage({
                        id: "REPORT.ITEM_REFUNDED_COUNT"
                    })
                ] = (report.ItemStatistics || {})[item].filter(it => it.RefundType).reduce((s, i) => s + i.quantity, 0)
                formattedReport[
                    intl.formatMessage({
                        id: "REPORT.ITEM_CANCELLED_COUNT"
                    })
                ] = (report.ItemStatistics || {})[item].filter(it => it.Status === 2).reduce((s, i) => s + i.quantity, 0)
                formattedReport[
                    intl.formatMessage({
                        id: "REPORT.ITEM_TOTAL_COUNT"
                    })
                ] = (report.ItemStatistics || {})[item].filter(it => it.Status !== 2 && !it.RefundType).reduce((s, i) => s + i.quantity, 0)
                formattedReport[
                    intl.formatMessage({
                        id: "REPORT.ORDER_DATE"
                    })
                ] = moment(report.Date).format("DD-MM-YYYY")
                formattedReport[
                    intl.formatMessage({
                        id: "REPORT.FIRST_ORDER_TO_DATE"
                    })
                ] = moment(report.Items[0].OrderToDate).format("DD-MM-YYYY")
                items.push(formattedReport);
            })

        });
        const formattedReport = {}
        formattedReport[
            intl.formatMessage({
                id: "REPORT.CUSTOMER"
            })
        ] = intl.formatMessage({
            id: "REPORT.TOTAL"
        })
        formattedReport[
            intl.formatMessage({
                id: "REPORT.USER_NAME"
            })
        ] = ''
        formattedReport[
            intl.formatMessage({
                id: "REPORT.AMOUNT"
            })
        ] = amount
        formattedReport[
            intl.formatMessage({
                id: "REPORT.APPROVAL_NUMBER"
            })
        ] = ''
        formattedReport[
            intl.formatMessage({
                id: "REPORT.LAST_CREDIT_CARD_DIGITS"
            })
        ] = ''
        formattedReport[
            intl.formatMessage({
                id: "REPORT.SUPPLIER_NAME"
            })
        ] = ''
        formattedReport[
            intl.formatMessage({
                id: "REPORT.ITEM_NAME"
            })
        ] = ''
        formattedReport[
            intl.formatMessage({
                id: "REPORT.ORDER_DATE"
            })
        ] = ''
        formattedReport[
            intl.formatMessage({
                id: "REPORT.FIRST_ORDER_TO_DATE"
            })
        ] = ''
        formattedReport[
            intl.formatMessage({
                id: "REPORT.ITEM_QUANTITY"
            })
        ] = quantity
        formattedReport[
            intl.formatMessage({
                id: "REPORT.ITEM_REFUNDED_COUNT"
            })
        ] = quantityRefunded
        formattedReport[
            intl.formatMessage({
                id: "REPORT.ITEM_CANCELLED_COUNT"
            })
        ] = quantityCancelled
        formattedReport[
            intl.formatMessage({
                id: "REPORT.ITEM_TOTAL_COUNT"
            })
        ] = quantity - quantityCancelled - quantityRefunded
        items.push(formattedReport);
        return items;
    }

    return (
        <>
            {searchParams.customer && (
                <FilterPanel
                    onSearchParamsSave={handleSearchParamsSave}
                    customerData={customerData}
                    initialSearchParams={searchParams}
                    isCustomerAdmin={userRole === "Customer Admin"}
                />
            )}
            {reportsData.reports.length !== 0 && (
                <div className="text-right">
                    <ExportButton
                        exportData={getExportData()}
                        fileName={exportFileName}
                    />
                </div>
            )}
            <ReportsList reportsData={reportsData} isCompany={true} />

        </>
    )
}
export default withRouter(
    injectIntl(connect(null, snackbarRedux.actions)(CreditCustomerReportsPage))
)
