/* eslint-disable no-restricted-imports */

import { makeStyles, Typography } from "@material-ui/core"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import ItemList from "../modules/Reports/RefundReports/ItemList"
import { connect } from "react-redux"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import { withRouter } from "react-router"
import FilterPanel from "../modules/Reports/RefundReports/FilterPanel"
import ExportButton from "../modules/Reports/ExportButton"
import { getCustomersList } from "../modules/Customers/_axios/customerCrud"
import { getSuppliers } from "../modules/Suppliers/_axios/supplierCrud"
import {
    getRefundReports
} from "../modules/Reports/_axios/reportsCrud"
import { formatDateForApi, formatDate } from "../modules/Common/momentFunctions"
import DishLoader from "../modules/Common/DishLoader"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: "relative",
        width: "100%",
        minHeight: 400
    }
}))

function RefundAdminPage({ intl, ...props }) {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [itemsData, setItemsData] = useState({
        itemsCount: 0,
        displayedData: [],
        isLoading: true,
        notRequested: true
    })
    const [customerData, setCustomerData] = useState({
        customers: [],
        isLoading: true
    })
    const [supplierData, setSupplierData] = useState({
        suppliers: [],
        isLoading: true
    })

    const [searchParams, setSearchParams] = useState({})
    const [paginationState, setPaginationState] = useState()

    const [refundFormPanelData, setRefundFormPanelData] = useState({
        show: false,
        orderItemId: null,
        subsidyAmount: null
    })
    const [
        creditCardRefundFormPanelData,
        setCreditCardRefundFormPanelData
    ] = useState({
        show: false,
        orderItemId: null,
        employeeAmount: null,
        type: null
    })

    function handlePaginationStateChange(changes) {
        const newState = { ...paginationState, ...changes }
        setPaginationState(newState)
    }

    function fetchItemHistory(cancelToken) {
        const { dateFrom, dateTo, customer, location, supplier } = searchParams
        const { page, rowsPerPage } = paginationState
        const params = {
            dateFrom: formatDateForApi(dateFrom),
            dateTo: formatDateForApi(dateTo),
            offset: page,
            limit: rowsPerPage
        }
        if (customer.CustomerID) {
            params.customerid = customer.CustomerID
            if (location) {
                params.locationid = location
            }
        }
        if (supplier.SupplierID) {
            params.supplierid = supplier.SupplierID
        }

        props.history.push({
            pathname: "/reports-refund",
            search: "?" + new URLSearchParams(params).toString()
        })

        setItemsData({ ...itemsData, isLoading: true })
        getRefundReports(
            {
                DateFrom: params.dateFrom,
                DateTo: params.dateTo,
                Offset: params.offset,
                Limit: params.limit,
                CustomerId: params.customerid,
                LocationId: params.locationid,
                SupplierId: params.supplierid
            },
            cancelToken.token
        )
            .then(({ data }) => {
                setItemsData({
                    itemsCount: data.total,
                    displayedData: data.data,
                    isLoading: false,
                    notRequested: false
                })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_REFUND_ITEMS"
                    })
                )
            )
    }

    function _generalUpdateItems(
        { SubsidyRefund, EmployeeRefundSum, RefundType, RefundReason, orderItemId },
        creditCardRefundFormPanelData
    ) {
        const items = [...itemsData.displayedData]
        const changedItem = items.find(
            item => item.OrderItemID === creditCardRefundFormPanelData.orderItemId
        )
        if (EmployeeRefundSum) {
            changedItem.EmployeeRefundAmount = EmployeeRefundSum
        } else {
            changedItem.RefundDate = formatDateForApi(new Date())
            changedItem.SubsidyRefundAmount = SubsidyRefund
            changedItem.RefundType = RefundType
        }
        changedItem.RefundReason = RefundReason
        setItemsData({
            ...itemsData,
            displayedData: items,
            isLoading: false,
            notRequested: false
        })
    }

    function updateItemsForCredit(obj) {
        _generalUpdateItems(obj, creditCardRefundFormPanelData)
    }
    function updateItems(obj) {
        _generalUpdateItems(obj, refundFormPanelData)
    }

    function fetchCustomers(cancelToken) {
        getCustomersList(cancelToken.token, true, "active")
            .then(({ data }) => {
                setCustomerData({ customers: data, isLoading: false })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
                    })
                )
            )
    }

    function fetchSuppliers(cancelToken) {
        getSuppliers(cancelToken.token, "active")
            .then(({ data }) => {
                setSupplierData({ suppliers: data, isLoading: false })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
                    })
                )
            )
    }


    useEffect(() => {
        const cancelToken = axios.CancelToken.source()
        fetchCustomers(cancelToken)
        fetchSuppliers(cancelToken)
        return () => cancelToken.cancel()
    }, [])

    function refundExportDataParser(data) {

        const employeeRefundAmount = data.reduce((s, t) => s + t.WalletRefundAmount + t.CostumerCreditRefundAmount + t.CreditCardRefundAmount + t.BudgetRefundAmount, 0)
        const subsidyRefundAmount = data.reduce((s, t) => s + t.SubsidyRefundAmount, 0)

        const items = data.map(item => {
            const object = {};
            object[intl.formatMessage({
                id: "TABLE.HEAD_LABELS.EMPLOYEE_NAME"
            })] = item.UserName
            object[intl.formatMessage({
                id: "TABLE.HEAD_LABELS.RESTAURANT"
            })] = item.SupplierName
            object[intl.formatMessage({
                id: "TABLE.HEAD_LABELS.ITEM_NAME"
            })] = item.ItemName
            object[intl.formatMessage({
                id: "TABLE.HEAD_LABELS.CUSTOMER"
            })] = item.CustomerName
            object[intl.formatMessage({
                id: "TABLE.HEAD_LABELS.LOCATION"
            })] = item.CustomerName
            object[intl.formatMessage({
                id: "TABLE.HEAD_LABELS.ORDER_DATE"
            })] = formatDate(item.OrderDate)
            object[intl.formatMessage({
                id: "TABLE.HEAD_LABELS.ORDER_TO_DATE"
            })] = formatDate(item.OrderToDate)
            object[intl.formatMessage({
                id: "TABLE.HEAD_LABELS.REFUND_DATE"
            })] = formatDate(item.RefundDate)
            object[intl.formatMessage({
                id: "TABLE.HEAD_LABELS.EMPLOYEE_REFUND_AMOUNT"
            })] = item.EmployeeRefundAmount
            object[intl.formatMessage({
                id: "TABLE.HEAD_LABELS.SUBSIDY_REFUND_AMOUNT"
            })] = item.SubsidyRefundAmount
            object[intl.formatMessage({
                id: "TABLE.HEAD_LABELS.REFUND_TYPE"
            })] = renderRefundType(item.RefundType)
            object[intl.formatMessage({
                id: "TABLE.HEAD_LABELS.REFUND_COMMENT"
            })] = item.RefundReason
            return object;
        })


        const object = {};
        object[intl.formatMessage({
            id: "TABLE.HEAD_LABELS.EMPLOYEE_NAME"
        })] = intl.formatMessage({
            id: "REPORT.TOTAL"
        })
        object[intl.formatMessage({
            id: "TABLE.HEAD_LABELS.RESTAURANT"
        })] = ''
        object[intl.formatMessage({
            id: "TABLE.HEAD_LABELS.ITEM_NAME"
        })] = ''
        object[intl.formatMessage({
            id: "TABLE.HEAD_LABELS.CUSTOMER"
        })] = ''
        object[intl.formatMessage({
            id: "TABLE.HEAD_LABELS.LOCATION"
        })] = ''
        object[intl.formatMessage({
            id: "TABLE.HEAD_LABELS.ORDER_DATE"
        })] = ''
        object[intl.formatMessage({
            id: "TABLE.HEAD_LABELS.ORDER_TO_DATE"
        })] = ''
        object[intl.formatMessage({
            id: "TABLE.HEAD_LABELS.REFUND_DATE"
        })] = ''
        object[intl.formatMessage({
            id: "TABLE.HEAD_LABELS.EMPLOYEE_REFUND_AMOUNT"
        })] = employeeRefundAmount
        object[intl.formatMessage({
            id: "TABLE.HEAD_LABELS.SUBSIDY_REFUND_AMOUNT"
        })] = subsidyRefundAmount
        object[intl.formatMessage({
            id: "TABLE.HEAD_LABELS.REFUND_TYPE"
        })] = ''
        object[intl.formatMessage({
            id: "TABLE.HEAD_LABELS.REFUND_COMMENT"
        })] = ''

        items.push(object);

        return items;
    }

    function renderRefundType(value) {
        switch (value) {
            case 1:
                return `${intl.formatMessage({
                    id: "REFUND_TYPE.CUSTOMER"
                })}`
            case 2:
                return `${intl.formatMessage({
                    id: "REFUND_TYPE.SUPPLIER"
                })}`
            case 3:
                return `${intl.formatMessage({
                    id: "REFUND_TYPE.BOTH"
                })}`
            default:
                return null
        }
    }

    function handleExport(response) {
        const cancelToken = axios.CancelToken.source()
        const { dateFrom, dateTo, customer, location, supplier } = searchParams
        const params = {
            dateFrom: formatDateForApi(dateFrom),
            dateTo: formatDateForApi(dateTo),
        }
        if (customer.CustomerID) {
            params.customerid = customer.CustomerID
            if (location) {
                params.locationid = location
            }
        }
        if (supplier.SupplierID) {
            params.supplierid = supplier.SupplierID
        }
        getRefundReports(
            {
                DateFrom: params.dateFrom,
                DateTo: params.dateTo,
                CustomerId: params.customerid,
                LocationId: params.locationid,
                SupplierId: params.supplierid
            },
            cancelToken.token
        )
            .then(({ data }) => {
                response(refundExportDataParser(data.data), `${intl.formatMessage({
                    id: "EXPORT_FILE_NAME.REPORT"
                })}_${formatDate(new Date())}`)
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_REFUND_ITEMS"
                    })
                )
            )
    }

    useEffect(() => {
        if (!customerData.isLoading && !supplierData.isLoading) {
            const urlParams = new URLSearchParams(window.location.search)

            const rowsPerPageValue = urlParams.get("limit")
            const rowsPerPage = rowsPerPageValue ? +rowsPerPageValue : 50
            const pageValue = urlParams.get("offset")
            const page = pageValue ? +pageValue : 0
            const sortBy = urlParams.get("sortBy") || "FirstName"
            const orderBy = urlParams.get("orderBy") || "asc"

            const dateFrom = urlParams.get("dateFrom")
                ? new Date(urlParams.get("dateFrom"))
                : new Date()
            const dateTo = urlParams.get("dateTo")
                ? new Date(urlParams.get("dateTo"))
                : new Date()
            const customer =
                customerData.customers.find(
                    customer => customer.CustomerID === urlParams.get("customerid")
                ) || {}
            const location = urlParams.get("locationid") || ""
            const supplier =
                supplierData.suppliers.find(
                    supplier => supplier.SupplierID === urlParams.get("supplierid")
                ) || {}

            setSearchParams({
                dateFrom,
                dateTo,
                customer,
                location,
                supplier
            })

            setPaginationState({
                page,
                rowsPerPage,
                orderBy,
                sortBy
            })
        }
    }, [customerData, supplierData])

    useEffect(() => {
        if (searchParams.dateFrom && searchParams.dateTo && paginationState.rowsPerPage) {
            const cancelToken = axios.CancelToken.source()
            fetchItemHistory(cancelToken)
            return () => cancelToken.cancel()
        }
    }, [searchParams, paginationState])

    return (
        <>
            {searchParams.customer && searchParams.supplier && (
                <FilterPanel
                    onSearchParamsSave={searchParams => setSearchParams(searchParams)}
                    customerData={customerData}
                    supplierData={supplierData}
                    initialSearchParams={searchParams}
                />
            )}
            <div className={classes.wrapper}>
                {itemsData.isLoading ? (
                    <DishLoader centered />
                ) : itemsData.itemsCount !== 0 ? (
                    <div>
                        <div className="d-inline">
                            <ExportButton exportData={itemsData.displayedData}
                                fileName={`${intl.formatMessage({
                                    id: "EXPORT_FILE_NAME.REPORT"
                                })}_${formatDate(new Date())}`}
                                onHandleData={handleExport}
                                isLoading={itemsData.isLoading} />
                        </div>
                        <ItemList
                            paginationState={paginationState}
                            onPageChange={handlePaginationStateChange}
                            itemsData={itemsData}
                            openRefundDialog={(orderItemId, subsidyAmount) => {
                                setRefundFormPanelData({
                                    show: true,
                                    orderItemId,
                                    subsidyAmount
                                })
                            }}
                            openCreditCardRefundDialog={(orderItemId, employeeAmount, type) =>
                                setCreditCardRefundFormPanelData({
                                    show: true,
                                    orderItemId,
                                    employeeAmount,
                                    type
                                })
                            }
                        />

                    </div>
                ) : itemsData.notRequested ? null : (
                    <Typography variant="h4" className={classes.heading}>
                        <FormattedMessage id="COMMON.NO_RESULTS" />
                    </Typography>
                )}
            </div>
        </>
    )




}

export default injectIntl(
    withRouter(connect(null, snackbarRedux.actions)(RefundAdminPage))
)
