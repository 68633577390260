import axios from "axios"

export function getUserInfo(id, cancelToken) {
  return axios.get(`/Login/GetUserInfo/${id}`, {
    cancelToken
  })
}

export function findpaymentmethods(id, orderid) {
  return axios.get(`/Order/GetPaymentMethods?userID=${id}&orderId=${orderid}`)
}

export function finduserInfo(id) {
  return axios.get(`/users/${id}`)
}

export function getUserMealInfo(userID, mealID, date, mealDeliveryTimeId, cancelToken) {
  return axios.get(`/UserMealInfo/${userID}/${mealID}/${date}?deliveryTimeId=${mealDeliveryTimeId}`, {
    cancelToken
  })
}

export function getStoreMealInfo(userID, mealID, date, mealDeliveryTimeId) {
  return axios.get(`/UserMealInfo/${userID}/${mealID}/Store?date=` + date +
        `&deliveryTimeId=${mealDeliveryTimeId}`)
}

export function updateOrder(userID, orderID, orderItemID, data) {
  return axios.patch(
    `/User/${userID}/Orders/${orderID}/Item/${orderItemID}`,
    data
  )
}

export function updateComboOrder(userID, orderID, orderItemID, data) {
  return axios.patch(
      `/User/${userID}/Orders/${orderID}/Combo/${orderItemID}`,
      data
  )
}

export function deleteOrder(userID, orderID, itemID) {
  return axios.post(
    `/User/${userID}/Orders/${orderID}/Item/${itemID}/Cancellation`,
    null
  )
}

export function deleteOrders(data) {
  return axios.post(
    `/Orders/CancelOrdersAdmin`,
    data
  )
}

export function saveItemToTempStorage(userID, orderItem) {
  return axios.post(`/User/${userID}/Orders/Item`, {
    Item: orderItem
  })
}

export function saveComboToTempStorage(userID, orderItem) {
  return axios.post(`/User/${userID}/Orders/Combo`, {
    Combo: orderItem
  })
}

export function getMealItemUserInfo(itemID, mealID, userID, date, orderItemId) {
  return axios.get(
    `/Item/${itemID}/MealItemUserInfo/${mealID}/${userID}/${date}/details`,
    { params: { orderItemId } }
  )
}

export function getMealComboUserInfo(itemID, mealID, userID, date, orderComboId) {
  return axios.get(
      `/combo/${itemID}/MealComboUserInfo/${mealID}/${userID}/${date}/details`,
      { params: { orderComboId } }
  )
}

export function getSummary(userID, orderID) {
  return axios.post(`/User/${userID}/Orders/Summary`, {
    Id: orderID
  })
}

export function sendOrder(userID, data, paymentMethods, creditCard) {
  const itempayments = [];
  paymentMethods.forEach(it => {
    if (it['type'] === '1') {
      itempayments.push(`budget=${it.value}`);
    }
    if (it['type'] === '2') {
      itempayments.push(`wallet=${it.value}`);
    }
    if (it['type'] === '3') {
      itempayments.push(`customerCredit=${it.value}`);
    }
    if (it['type'] === '4') {
      itempayments.push(`creditCard=${it.value}`);
      itempayments.push(`ccExpDate=${creditCard.expirationMonth}${creditCard.expirationYear}`);
      itempayments.push(`ccToken=${creditCard.creditCardToken}`);
    }
  })
  return axios.post(`/Order/Submit?orderid=${data.OrderId}&userID=${data.UserID}&${itempayments.join('&')}`, null)
}

export function getPaymentStatus(userID) {
  return axios.post(`/User/${userID}/Orders/Payment/Status`, {})
}

export function getCreditCardToken(userID) {
  return axios.get("/User/Orders/GetCCTokent", {
    params: {
      userID
    }
  })
}
