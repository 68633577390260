/* eslint-disable no-restricted-imports */

import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { useStyles } from "../../Common/_styles/elementListStyles"
import { getSorting, stableSort } from "../../Common/tableSortingFunctions"

const headRows = [
  {
    id: "ItemName",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.ITEM_NAME" />
  },
  {
    id: "Quantity",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.QUANTITY" />
  }
]

function CustomRow({ row, indexId, classes }) {

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        className={classes.collapsibleRow}
      >
        <TableCell className={classes.itemName}>{row.ItemName}</TableCell>
        <TableCell className={classes.itemQuantity}>{row.Quantity}</TableCell>
      </TableRow>
    </React.Fragment>
  )
}

function MealsGroupedList({ ordersData }) {
  const classes = useStyles()
  const rows = ordersData.GroupedItems
  const [sortBy, setSortBy] = useState("ItemName")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

    if (ordersData && ordersData.GroupedItems) {
        return (
            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}>
                    <Table aria-labelledby="tableTitle" size="small">
                        <EnhancedTableHead
                            headRows={headRows}
                            orderBy={orderBy}
                            sortBy={sortBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {stableSort(rows, getSorting(orderBy, sortBy)).map(row => {
                                return (
                                    <CustomRow key={row.ItemId} row={row} classes={classes} />
                                )
                            })}
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        )
    } else {
        return (<div></div>)
    }
}

export default MealsGroupedList
