import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FilledInput,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core"
import { useFormik } from "formik"
import React, { useState } from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import * as xlsx from "xlsx"
import * as Yup from "yup"
import UploadedUsersList from "./UploadedUsersList"
import { useStyles } from "../../Common/_styles/formDialogStyles"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../../redux/snackbar/snackbarHandlers"

function UpdateUsersFormDialog({
  show,
  closeDialog,
  allDepartments,
  allLocations,
  handleSubmit,
  intl
}) {
  const dispatch = useDispatch()

  const [headers, setHeaders] = useState([])
  const [lines, setLines] = useState([])
  const [uploadedUsers, setUploadedUsers] = useState([])
  const [updatedUsers, setUpdatedUsers] = useState([])
  const [notUpdatedUsers, setNotUpdatedUsers] = useState([])
  const classes = useStyles()
  const [uploading, setUploading] = useState({
    users: false,
  })
  function filePathset(e) {
    e.stopPropagation()
    e.preventDefault()
    const file = e.target.files[0]
    readFile(file)
  }

  function readFile(file) {
    const reader = new FileReader()
    reader.onload = e => {
      /* Parse data */
      const bstr = e.target.result
      const wb = xlsx.read(bstr, { type: "binary" })
      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      /* Convert array of arrays */
      const data = xlsx.utils.sheet_to_csv(ws, { header: 1 })
      setLines(convertToArray(data))
    }
    reader.readAsBinaryString(file)
  }

  function convertToArray(csv) {
    const lines = csv.split("\n")

    const result = []

    const headers = lines[0].split(",")
    setHeaders(headers)

    for (let i = 1; i < lines.length; i++) {
      const obj = {}
      const currentline = lines[i].split(",")

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j]
      }

      result.push(obj)
    }

    return result
  }

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    locations: [],
    department: ""
  }

  //const commonValidationSchema = Yup.string().required(
  //  intl.formatMessage({
  //    id: "VALIDATION.REQUIRED_FIELD"
  //  })
  //)

  const emailValidationSchema = Yup.string().when("phone", {
    is: phone => {
      return !phone
    },
    then: Yup.string().required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )
  })

  const phoneValidationSchema = Yup.string().when("email", {
    is: email => {
      return !email
    },
    then: Yup.string().required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )
  })

  const validationSchema = Yup.object().shape(
    {
      email: emailValidationSchema,
      phone: phoneValidationSchema
    },
    ["email", "phone", "firstName", "lastName", "locations"]
  )

  const formikForMapping = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const {
        firstName,
        lastName,
        email,
        phone,
        locations,
        department,
        externalID,
      } = values
      setSubmitting(true)
      let users = []
      let tempID = 0
      users = lines.map(line => ({
        TempID: tempID++,
        FirstName: line[firstName],
        LastName: line[lastName],
        ExternalID: line[externalID],
        Email: email ? line[email] : "",
        Mobile: phone ? line[phone] : "",
        DepartmentName: department ? line[department] : "",
        Locations: locations ? line[locations] : ""
      }))
      setUploadedUsers(
        users.filter(
          user =>
            user.FirstName ||
            user.LastName ||
            user.ExternalID ||
            user.Email ||
            user.Mobile ||
            user.DepartmentName ||
            user.Locations
        )
      )
      setSubmitting(false)
    }
  })

  function handleCreate() {
    setUploading({
      users: true,
    })
    handleSubmit(uploadedUsers)
      .then(({ data }) => {
        const notUpdatedUsersIds = data.notUpdatedUsers.map(
          user => user.rowNumber - 1
        )
        const notUpdatedUsers = []
        const updatedUsers = []
        uploadedUsers.forEach(user => {
          if (notUpdatedUsersIds.includes(user.TempID)) {
            notUpdatedUsers.push({
              ...user,
              errorCode: data.notUpdatedUsers
                .filter(el => el.rowNumber - 1 === user.TempID)
                .map(el => {
                  switch (el.errorCode) {
                    case 1:
                      return intl.formatMessage({
                        id: "UPLOAD_FORM.VALIDATION.NAME_EMPTY"
                      })
                    case 2:
                      return intl.formatMessage({
                        id: "UPLOAD_FORM.VALIDATION.INVALID_EMAIL"
                      })
                    case 3:
                      return intl.formatMessage({
                        id: "ADMIN_USERS.VALIDATION.EMAIL_USED"
                      })
                    case 4:
                      return intl.formatMessage({
                        id: "UPLOAD_FORM.VALIDATION.INVALID_MOBILE"
                      })
                    case 5:
                      return intl.formatMessage({
                        id: "ADMIN_USERS.VALIDATION.MOBILE_USED"
                      })
                    case 6:
                      return intl.formatMessage({
                        id: "UPLOAD_FORM.VALIDATION.EMPTY_MOBILE_AND_EMAIL"
                      })
                    case 7:
                      return intl.formatMessage({
                        id: "UPLOAD_FORM.VALIDATION.DEPARTMENT_NOT_FOUND"
                      })
                    case 8:
                      return intl.formatMessage({
                        id: "UPLOAD_FORM.VALIDATION.LOCATION_EMPTY"
                      })
                    case 9:
                      return intl.formatMessage({
                        id: "UPLOAD_FORM.VALIDATION.LOCATION_NOT_FOUND"
                      })
                    case 10:
                      return intl.formatMessage({
                        id: "UPLOAD_FORM.VALIDATION.USER_NOT_FOUND"
                      })
                    case 11:
                      return el.errorDescription
                    default:
                      return null
                  }
                })
                .filter(el => el !== null)
                .join(", ")
            })
          } else {
            updatedUsers.push(user)
          }
        })
        setUpdatedUsers(updatedUsers)
        setNotUpdatedUsers(notUpdatedUsers)
        setUploading({
          users: false,
        })
      })
      .catch(error => {
            handleApiError(
                dispatch,
                error,
                intl.formatMessage({
                  id: "API.ERROR.FAILED_TO_UPDATE_USERS"
                })
            )
        setUploading({
          users: true,
        })
      }
      )
  }

  function handleClose() {
    setHeaders([])
    setLines([])
    setUploadedUsers([])
    setUpdatedUsers([])
    setNotUpdatedUsers([])
    formikForMapping.resetForm()
    closeDialog()
  }

    function isDataInvalid(user) {
      return (!user.Email && !user.Mobile)
    //return (
    //  !user.FirstName ||
    //  !user.LastName ||
    //  (!user.Email && !user.Mobile) ||
    //  !user.Locations
    //)
  }

  function checkForError(fieldName) {
    if (
      formikForMapping.touched[fieldName] &&
      formikForMapping.errors[fieldName]
    ) {
      return true
    }
    return false
  }

  function renderErrors(fieldName) {
    return checkForError(fieldName) ? (
      <span>{formikForMapping.errors[fieldName]}</span>
    ) : null
  }

  function handleUserDataChange(id, event, fieldName) {
    const value = event.target.value
    const users = [...uploadedUsers]
    const changedUser = users.find(user => user.TempID === id)
    changedUser[fieldName] = value
    setUploadedUsers(users)
  }

  function handleDeleteUser(id) {
    const users = uploadedUsers.filter(user => user.TempID !== id)
    setUploadedUsers(users)
  }

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title" disableTypography={true}>
        <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
          <FormattedMessage id="ADMIN_USERS.UPDATE_FORM.TITLE" />
        </h3>
      </DialogTitle>
      <form
        onSubmit={formikForMapping.handleSubmit}
        autoComplete="off"
        className={classes.bigForm}
      >
        <DialogContent>
          {updatedUsers.length || notUpdatedUsers.length ? (
            <>
              <FormattedMessage id="UPLOAD_FORM.UPDATED_USERS" />
              <UploadedUsersList
                users={updatedUsers}
                isDataInvalid={isDataInvalid}
                variant="created"
              />
              <FormattedMessage id="UPLOAD_FORM.NOT_UPDATED_USERS" />
              <UploadedUsersList
                users={notUpdatedUsers}
                isDataInvalid={isDataInvalid}
                variant="created"
              />
            </>
          ) : uploadedUsers.length !== 0 ? (
            <UploadedUsersList
              users={uploadedUsers}
              allLocations={allLocations}
              allDepartments={allDepartments}
              isDataInvalid={isDataInvalid}
              onDelete={handleDeleteUser}
              onUserDataChange={handleUserDataChange}
            />
          ) : (
            <>
              <Button variant="contained" component="label">
                <FormattedMessage id="UPLOAD_FORM.UPDATE_FROM_FILE_BUTTON" />
                <input type="file" onChange={filePathset} hidden />
              </Button>
              {headers.length !== 0 ? (
                <>
                  <div className="position-relative">
                    <FormControl
                      variant="filled"
                      className={classes.textField}
                      fullWidth
                      error={checkForError("firstName")}
                    >
                      <InputLabel htmlFor="first-name-header-select">
                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.FIRST_NAME_FIELD.LABEL" />
                      </InputLabel>
                      <Select
                        {...formikForMapping.getFieldProps("firstName")}
                        input={
                          <FilledInput
                            name="firstName"
                            id="first-name-header-select"
                          />
                        }
                      >
                        {headers.map(header => (
                          <MenuItem key={header} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {renderErrors("lastName")}
                  </div>

                  <div className="position-relative">
                    <FormControl
                        variant="filled"
                        className={classes.textField}
                        fullWidth
                        error={checkForError("externalID")}
                    >
                      <InputLabel htmlFor="first-name-header-select">
                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.EXTERNAL_ID_FIELD.LABEL" />
                      </InputLabel>
                      <Select
                          {...formikForMapping.getFieldProps("externalID")}
                          input={
                            <FilledInput
                                name="externalID"
                                id="external-id-header-select"
                            />
                          }
                      >
                        {headers.map(header => (
                            <MenuItem key={header} value={header}>
                              {header}
                            </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {renderErrors("externalID")}
                  </div>

                  <div className="position-relative">
                    <FormControl
                      variant="filled"
                      className={classes.textField}
                      fullWidth
                      error={checkForError("lastName")}
                    >
                      <InputLabel htmlFor="last-name-header-select">
                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.LAST_NAME_FIELD.LABEL" />
                      </InputLabel>
                      <Select
                        {...formikForMapping.getFieldProps("lastName")}
                        input={
                          <FilledInput
                            name="lastName"
                            id="last-name-header-select"
                          />
                        }
                      >
                        {headers.map(header => (
                          <MenuItem key={header} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {renderErrors("lastName")}
                  </div>
                  <div className="position-relative">
                    <FormControl
                      variant="filled"
                      className={classes.textField}
                      fullWidth
                      error={checkForError("phone")}
                    >
                      <InputLabel htmlFor="phone-header-select">
                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.MOBILE_FIELD.LABEL" />
                      </InputLabel>
                      <Select
                        {...formikForMapping.getFieldProps("phone")}
                        input={
                          <FilledInput name="phone" id="phone-header-select" />
                        }
                      >
                        {headers.map(header => (
                          <MenuItem key={header} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {renderErrors("phone")}
                  </div>
                  <div className="position-relative">
                    <FormControl
                      variant="filled"
                      className={classes.textField}
                      fullWidth
                      error={checkForError("email")}
                    >
                      <InputLabel htmlFor="email-header-select">
                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.EMAIL_FIELD.LABEL" />
                      </InputLabel>
                      <Select
                        {...formikForMapping.getFieldProps("email")}
                        input={
                          <FilledInput name="email" id="email-header-select" />
                        }
                      >
                        {headers.map(header => (
                          <MenuItem key={header} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {renderErrors("email")}
                  </div>
                  <div className="position-relative">
                    <FormControl
                      variant="filled"
                      className={classes.textField}
                      fullWidth
                      error={checkForError("locations")}
                    >
                      <InputLabel htmlFor="locations-header-select">
                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.LOCATION_FIELD.LABEL" />
                      </InputLabel>
                      <Select
                        {...formikForMapping.getFieldProps("locations")}
                        input={
                          <FilledInput
                            name="locations"
                            id="locations-header-select"
                          />
                        }
                      >
                        {headers.map(header => (
                          <MenuItem key={header} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {renderErrors("locations")}
                  </div>
                  <div className="position-relative">
                    <FormControl
                      variant="filled"
                      className={classes.textField}
                      fullWidth
                      error={checkForError("department")}
                    >
                      <InputLabel htmlFor="department-header-select">
                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.DEPARTMENT_FIELD.LABEL" />
                      </InputLabel>
                      <Select
                        {...formikForMapping.getFieldProps("department")}
                        input={
                          <FilledInput
                            name="department"
                            id="department-header-select"
                          />
                        }
                      >
                        {headers.map(header => (
                          <MenuItem key={header} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {renderErrors("department")}
                  </div>
                </>
              ) : (
                <div style={{ height: 410.64 }} />
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            type="button"
            size="large"
            onClick={handleClose}
            className={classes.button}
          >
            <FormattedMessage id="ADMIN_USERS.CREATE_FORM.CANCEL_BUTTON" />
          </Button>
          <Button
            variant="contained"
            type={uploadedUsers.length !== 0 ? "button" : "submit"}
            size="large"
            color="secondary"
            className={classes.button}
            disabled={
              formikForMapping.isSubmitting || uploading.users ||
              uploadedUsers.find(user => isDataInvalid(user)) !== undefined
            }
            onClick={() =>
              updatedUsers.length || notUpdatedUsers.length
                ? handleClose()
                : uploadedUsers.length
                ? handleCreate()
                : null
            }
          >
            <FormattedMessage id="UPLOAD_FORM.CONTINUE_BUTTON" />
            {formikForMapping.isSubmitting && (
              <span className="ml-1 spinner spinner-white"></span>
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default injectIntl(UpdateUsersFormDialog)
