import moment from "moment"

export function formatDate(date) {
  return moment(date).format("DD/MM/YYYY")
}
export function formatUSDate(date) {
   return moment(date).format("MM/DD/YYYY")
}

export function formatUSDateTime(date) {
    return moment.locale('en') && moment(date).format("MM/DD/YYYY hh:mma");
}
export function formatDateTime(date) {
  return moment(date).format("DD/MM/YYYY HH:mm")
}

export function formatDateForApi(date) {
  return moment(date).format("YYYY-MM-DD")
}
