/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react"
import { useLocation } from "react-router"
import { NavLink } from "react-router-dom"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers"
import { FormattedMessage, injectIntl } from "react-intl"
import { connect } from "react-redux"
import { supplierUserRoles } from "../../../../../app/modules/Users/rolesTranslation"

function AsideMenuListNy({
  layoutProps,
  userRole,
  ownSupplierID,
  userCustomerInfo
}) {
  const location = useLocation()
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : ""
  }

  function renderEmployeeReportLink() {
    return (
      <li
        className={`menu-item ${getMenuItemActive("/reports-employee", false)}`}
        aria-haspopup="true"
      >
        <NavLink className="menu-link" to="/reports-employee">
          <span className="svg-icon menu-icon">
            <i className="far fa-chart-bar"></i>
          </span>
          <span className="menu-text">
            <FormattedMessage id="SIDENAV.EMPLOYEE_REPORTS" />
          </span>
        </NavLink>
      </li>
    )
  }


  function renderSupplierDetailsReportLink() {
    return (
      <li
        className={`menu-item ${getMenuItemActive("/reports-details-supplier", false)}`}
        aria-haspopup="true"
      >
        <NavLink className="menu-link" to="/reports-details-supplier">
          <span className="svg-icon menu-icon">
            <i className="far fa-chart-bar"></i>
          </span>
          <span className="menu-text">
            <FormattedMessage id="SIDENAV.SUPPLIER_DETAILS_REPORTS" />
          </span>
        </NavLink>
      </li>
    )
  }


  function renderSupplierReportLink() {
    return (
      <li
        className={`menu-item ${getMenuItemActive("/reports-supplier", false)}`}
        aria-haspopup="true"
      >
        <NavLink className="menu-link" to="/reports-supplier">
          <span className="svg-icon menu-icon">
            <i className="far fa-chart-bar"></i>
          </span>
          <span className="menu-text">
            <FormattedMessage id="SIDENAV.SUPPLIER_REPORTS" />
          </span>
        </NavLink>
      </li>
    )
  }

  const isUserAdmin = userRole === "Admin" || userRole === "Owner"

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {isUserAdmin ? (
          <li
            className={`menu-item ${getMenuItemActive("/suppliers", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/suppliers">
              <span className="svg-icon menu-icon">
                <i className="fas fa-utensils"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="SIDENAV.SUPPLIERS" />
              </span>
            </NavLink>
          </li>
        ) : (
          ["Supplier User", "Supplier Admin"].includes(userRole) && (
            <li
              className={`menu-item ${getMenuItemActive(
                `/supplier/${ownSupplierID}`,
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to={`/supplier/${ownSupplierID}`}>
                <span className="svg-icon menu-icon">
                  <i className="fas fa-utensils"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="SIDENAV.SUPPLIERS" />
                </span>
              </NavLink>
            </li>
          )
        )}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {["Admin","Owner", "Supplier Admin"].includes(userRole) && (
          <li
            className={`menu-item ${getMenuItemActive("/users", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/users">
              <span className="svg-icon menu-icon">
                <i className="fas fa-users-cog"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="SIDENAV.USERS" />
              </span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {isUserAdmin ? (
          <li
            className={`menu-item ${getMenuItemActive("/customer", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/customers">
              <span className="svg-icon menu-icon">
                <i className="fas fa-user"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="SIDENAV.CUSTOMERS" />
              </span>
            </NavLink>
          </li>
        ) : (
          userRole === "Customer Admin" && (
            <li
              className={`menu-item ${getMenuItemActive(
                `/customer/${userCustomerInfo.CustomerID}`,
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink
                className="menu-link"
                to={`/customer/${userCustomerInfo.CustomerID}`}
              >
                <span className="svg-icon menu-icon">
                  <i className="fas fa-utensils"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="SIDENAV.CUSTOMERS" />
                </span>
              </NavLink>
            </li>
          )
        )}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {isUserAdmin && (
          <li
            className={`menu-item ${getMenuItemActive("/tags", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/tags">
              <span className="svg-icon menu-icon">
                <i className="fas fa-tags"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="SIDENAV.TAGS" />
              </span>
            </NavLink>
          </li>
        )}
        {isUserAdmin && (
            <li
                className={`menu-item ${getMenuItemActive("/specialDates", false)}`}
                aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/specialDates">
              <span className="svg-icon menu-icon">
                <i className="fas fa-tags"></i>
              </span>
                <span className="menu-text">
                <FormattedMessage id="SIDENAV.SPECIAL_DATES" />
              </span>
              </NavLink>
            </li>
        )}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {isUserAdmin && (
          <li
            className={`menu-item ${getMenuItemActive("/faq", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/faq">
              <span className="svg-icon menu-icon">
                <i className="far fa-question-circle"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="SIDENAV.FAQ" />
              </span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {isUserAdmin && (
          <li
            className={`menu-item ${getMenuItemActive("/refund", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/refund">
              <span className="svg-icon menu-icon">
                <i className="fas fa-hand-holding-usd"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="SIDENAV.REFUND" />
              </span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {isUserAdmin && (
          <li
            className={`menu-item ${getMenuItemActive("/order-admin", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/order-admin">
              <span className="svg-icon menu-icon">
                <i className="fas fa-hand-holding-usd"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="SIDENAV.ORDER" />
              </span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {userRole === "Customer Admin" && renderEmployeeReportLink()}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {userRole === "Customer Admin" && 
            <li
              className={`menu-item ${getMenuItemActive(
                "/reports-customer-credit",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/reports-customer-credit">
                <span className="svg-icon menu-icon">
                  <i className="far fa-chart-bar"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="SIDENAV.CREDIT_CUSTOMER_REPORTS" />
                </span>
              </NavLink>
            </li>
        }
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {userRole === "Supplier Admin" && renderSupplierDetailsReportLink()}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {isUserAdmin && (
          <>
            <li
              data-menu-toggle={layoutProps.menuDesktopToggle}
              aria-haspopup="true"
              className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
                "/reports"
              )}`}
            >
              <NavLink className="menu-link menu-toggle" to="/reports">
                <span className="svg-icon menu-icon">
                  <i className="far fa-chart-bar"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="SIDENAV.REPORTS" />
                </span>
                <i className="menu-arrow"></i>
              </NavLink>
              <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                <ul className="menu-subnav">
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/reports-order",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/reports-order">
                      <span className="svg-icon menu-icon">
                        <i className="far fa-chart-bar"></i>
                      </span>
                      <span className="menu-text">
                        <FormattedMessage id="SIDENAV.ORDER_REPORTS" />
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  {renderEmployeeReportLink()}
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/reports-customer-credit",
                        false
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/reports-customer-credit">
                        <span className="svg-icon menu-icon">
                          <i className="far fa-chart-bar"></i>
                        </span>
                        <span className="menu-text">
                          <FormattedMessage id="SIDENAV.CREDIT_CUSTOMER_REPORTS" />
                        </span>
                      </NavLink>
                    </li>
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  {/* <li
                    className={`menu-item ${getMenuItemActive("/reports-wallet-balance", false)}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/reports-wallet-balance">
                      <span className="svg-icon menu-icon">
                        <i className="far fa-chart-bar"></i>
                      </span>
                      <span className="menu-text">
                        <FormattedMessage id="SIDENAV.WALLET_BALANCE_REPORTS" />
                      </span>
                    </NavLink>
                  </li> */}
                  {/* <li
                      className={`menu-item ${getMenuItemActive("/reports-bank-transfer", false)}`}
                      aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/reports-bank-transfer">
                      <span className="svg-icon menu-icon">
                        <i className="far fa-chart-bar"></i>
                      </span>
                      <span className="menu-text">
                        <FormattedMessage id="SIDENAV.BANK_TRANSFER_REPORTS" />
                      </span>
                    </NavLink>
                  </li> */}
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  {/* <li
                    className={`menu-item ${getMenuItemActive("/reports-budget-usage", false)}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/reports-budget-usage">
                      <span className="svg-icon menu-icon">
                        <i className="far fa-chart-bar"></i>
                      </span>
                      <span className="menu-text">
                        <FormattedMessage id="SIDENAV.BUDGET_USAGE_REPORTS" />
                      </span>
                    </NavLink>
                  </li> */}
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  {/* <li
                    className={`menu-item ${getMenuItemActive(
                      "/reports-credit",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/reports-credit">
                      <span className="svg-icon menu-icon">
                        <i className="far fa-chart-bar"></i>
                      </span>
                      <span className="menu-text">
                        <FormattedMessage id="SIDENAV.CREDIT_REPORTS" />
                      </span>
                    </NavLink>
                  </li> */}
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  {renderSupplierReportLink()}
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/reports-details-supplier",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/reports-details-supplier"
                    >
                      <span className="svg-icon menu-icon">
                        <i className="far fa-chart-bar"></i>
                      </span>
                      <span className="menu-text">
                        <FormattedMessage id="SIDENAV.SUPPLIER_DETAILS_REPORTS" />
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/reports-details-customer",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/reports-details-customer"
                    >
                      <span className="svg-icon menu-icon">
                        <i className="far fa-chart-bar"></i>
                      </span>
                      <span className="menu-text">
                        <FormattedMessage id="SIDENAV.CUSTOMER_DETAILS_REPORTS" />
                      </span>
                    </NavLink>
                  </li>

                  {/* <li
                    className={`menu-item ${getMenuItemActive(
                      "/reports-customer",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/reports-customer"
                    >
                      <span className="svg-icon menu-icon">
                        <i className="far fa-chart-bar"></i>
                      </span>
                      <span className="menu-text">
                        <FormattedMessage id="SIDENAV.CUSTOMER_REPORTS" />
                      </span>
                    </NavLink>
                  </li> */}


                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  {/* <li
                    className={`menu-item ${getMenuItemActive(
                      "/reports-item-orders",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/reports-item-orders">
                      <span className="svg-icon menu-icon">
                        <i className="far fa-chart-bar"></i>
                      </span>
                      <span className="menu-text">
                        <FormattedMessage id="SIDENAV.ITEM_ORDER_REPORTS" />
                      </span>
                    </NavLink>
                  </li> */}
                  <li
                      className={`menu-item ${getMenuItemActive(
                          "/reports-cancelled-item-orders",
                          false
                      )}`}
                      aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/reports-cancelled-item-orders">
                      <span className="svg-icon menu-icon">
                        <i className="far fa-chart-bar"></i>
                      </span>
                      <span className="menu-text">
                        <FormattedMessage id="SIDENAV.ITEM_CANCELLED_ORDER_REPORTS" />
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/reports-rating",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/reports-rating">
                      <span className="svg-icon menu-icon">
                        <i className="far fa-chart-bar"></i>
                      </span>
                      <span className="menu-text">
                        <FormattedMessage id="SIDENAV.RATING_REPORTS" />
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  {/* <li
                    className={`menu-item ${getMenuItemActive(
                      "/reports-store-customer",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/reports-store-customer">
                      <span className="svg-icon menu-icon">
                        <i className="far fa-chart-bar"></i>
                      </span>
                      <span className="menu-text">
                        <FormattedMessage id="SIDENAV.CUSTOMER_STORE_REPORTS" />
                      </span>
                    </NavLink>
                  </li> */}
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  {/* <li
                    className={`menu-item ${getMenuItemActive(
                      "/reports-store-supplier",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/reports-store-supplier">
                      <span className="svg-icon menu-icon">
                        <i className="far fa-chart-bar"></i>
                      </span>
                      <span className="menu-text">
                        <FormattedMessage id="SIDENAV.SUPPLIER_STORE_REPORTS" />
                      </span>
                    </NavLink>
                  </li> */}
                  {/*end::2 Level*/}

                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/reports-refund",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/reports-refund">
                      <span className="svg-icon menu-icon">
                        <i className="far fa-chart-bar"></i>
                      </span>
                      <span className="menu-text">
                        <FormattedMessage id="SIDENAV.REFUND_REPORTS" />
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}

                  {/*begin::2 Level*/}
                  {/* <li
                      className={`menu-item ${getMenuItemActive(
                          "/reports-pos-request-log",
                          false
                      )}`}
                      aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/reports-pos-request-log">
                      <span className="svg-icon menu-icon">
                        <i className="far fa-chart-bar"></i>
                      </span>
                      <span className="menu-text">
                        <FormattedMessage id="SIDENAV.POS_REQUEST_REPORTS" />
                      </span>
                    </NavLink>
                  </li> */}
                  {/*end::2 Level*/}

                </ul>
              </div>
            </li>
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            {/* <li className={`menu-item`} aria-haspopup="true">
              <a
                className="menu-link"
                href="https://my.tranzila.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="svg-icon menu-icon">
                  <i className="far fa-credit-card"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="SIDENAV.TRANZILA" />
                </span>
              </a>
            </li> */}
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            {/* <li className={`menu-item`} aria-haspopup="true">
              <a
                className="menu-link"
                href="https://onesignal.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="svg-icon menu-icon">
                  <i className="far fa-comment"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="SIDENAV.ONE_SIGNAL" />
                </span>
              </a>
            </li> */}
            {/*end::1 Level*/}

         

            {/*begin::1 Level*/}
            {/* {isUserAdmin && (<li
                  className={`menu-item ${getMenuItemActive(
                    "/reports-push",
                    false
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/reports-push">
                    <span className="svg-icon menu-icon">
                      <i className="far fa-comment"></i>
                    </span>
                    <span className="menu-text">
                      <FormattedMessage id="SIDENAV.PUSH_REPORTS" />
                    </span>
                  </NavLink>
                </li>
            )} */}
            {/*end::1 Level*/}




            {/*begin::1 Level*/}
            {isUserAdmin && (
                <li
                    className={`menu-item ${getMenuItemActive("/tags", false)}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/banner">
                    <span className="svg-icon menu-icon">
                      <i className="fas fa-ad"></i>
                    </span>
                    <span className="menu-text">
                      <FormattedMessage id="SIDENAV.BANNER" />
                    </span>
                  </NavLink>
                </li>
            )}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {isUserAdmin && (
                <li
                    className={`menu-item ${getMenuItemActive("/management-meals", false)}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/management-meals">
                    <span className="svg-icon menu-icon">
                      <i className="fas fa-utensils"></i>
                    </span>
                    <span className="menu-text">
                      <FormattedMessage id="SIDENAV.MEALS_MANAGEMENT" />
                    </span>
                  </NavLink>
                </li>
            )}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {/* {isUserAdmin && (
                <li
                    className={`menu-item ${getMenuItemActive("/combos", false)}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/combos">
                    <span className="svg-icon menu-icon">
                      <i className="fas fa-utensils"></i>
                    </span>
                    <span className="menu-text">
                      <FormattedMessage id="SIDENAV.COMBOS" />
                    </span>
                  </NavLink>
                </li>
            )} */}
            {/* {isUserAdmin && (
                <li
                    className={`menu-item ${getMenuItemActive("/price-categories", false)}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/price-categories">
                    <span className="svg-icon menu-icon">
                      <i className="fas fa-utensils"></i>
                    </span>
                    <span className="menu-text">
                      <FormattedMessage id="SIDENAV.PRICE_CATEGORY" />
                    </span>
                  </NavLink>
                </li>
            )} */}
            {/*end::1 Level*/}

          </>
        )}
      </ul>

      {/* end::Menu Nav */}
    </>
  )
}

function mapStateToProps(state) {
  return {
    ownSupplierID: state.auth.user.SupplierID,
    userRole: state.auth.user.Role,
    userCustomerInfo: state.auth.user.Customer
  }
}
export default injectIntl(connect(mapStateToProps, null)(AsideMenuListNy))
